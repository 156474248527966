<template>
  <v-app class="bg-img">
    <div class="py-3"></div>
    <v-container>
      <v-card>
        <div class="py-6"></div>
        <h2 class="text-center">お問い合わせ</h2>

        <v-responsive class="mx-auto mb-12" width="56">
          <v-divider class="mb-1"></v-divider>

          <v-divider></v-divider>
        </v-responsive>

        <v-theme-provider light>
          <form
            action="https://formcarry.com/s/G70FDBTQM"
            method="POST"
            accept-charset="UTF-8"
          >
            <!-- <v-sheet id="contact" tag="section"> -->
            <v-col cols="12">
              <v-text-field
                required
                solo
                background-color="grey lighten-2"
                color="cyan"
                label="お名前*"
                type="text"
                name="Name"
              ></v-text-field>
            </v-col>

            <v-col cols="12">
              <v-text-field
                flat
                required
                label="メールアドレス*"
                solo
                type="email"
                name="email"
              ></v-text-field>
            </v-col>

            <v-col cols="12">
              <v-select
                v-model="selectValue"
                :items="items"
                label="件名*"
                required
                flat
                solo
                type="text"
                name="subject"
              ></v-select>
            </v-col>

            <v-col cols="12">
              <v-textarea
                counter
                flat
                label="本文*"
                required
                solo
                type="text"
                name="Input"
                height="400px"
                v-model="myText"
              ></v-textarea>
            </v-col>

            <input type="hidden" name="_gotcha" />
            <v-col class="mx-auto" cols="auto">
              <v-row>
                <v-btn
                  x-large
                  type="submit"
                  class="mx-auto font-weight-bold white-text"
                  color="#ffff99"
                >
                  送信
                </v-btn>
                <div class="py-12"></div>
              </v-row>
            </v-col>
          </form>
        </v-theme-provider>
      </v-card>
    </v-container>
    <div class="py-12"></div>
  </v-app>
</template>
<script>
import { defineComponent, computed, reactive } from "vue";
import { useHead } from "@vueuse/head";

export default defineComponent({
  data() {
    return {
      items: [
        "サイトの使い方を教えてほしい",
        "サイトのバグ報告",
        "タイピングジャンルを追加してほしい",
        "取材したい",
        "その他",
      ],
      selectValue: "",
      myText: "",
    };
  },
  created() {
    this.setup();
  },
  methods: {
    // ヘッダー情報
    setup() {
      const siteData = reactive({
        title: "お問い合わせ|コリアンタイピング(韓国語のタイピング練習アプリ)",
        description:
          "不具合の報告やサイトの使い方等のお問い合わせはこちらからお願いします。",
      });
      useHead({
        // Can be static or computed
        title: computed(() => siteData.title),
        meta: [
          {
            name: "description",
            content: computed(() => siteData.description),
          },
        ],
      });
    },
  },
  watch: {},
});
</script>

<style scoped>
/* .v-application .font-weight-black[data-v-fae5bece]{
    text-shadow: 2px 3px 4px #ffc0cb;
  } */
.bg-img {
  background-image: url("~@/assets/bg.png");
}

.bg-white {
  background-color: white;
}
</style>

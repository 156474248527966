<template>
  <v-app id="inspire" class="bg-img">
    <div class="py-3"></div>

    <v-main class="grey lighten-3">
      <v-container>
        <div v-if="this.loding == true">
          <loding />
        </div>
        <div v-else>
          <v-row>
            <!-- <v-col cols="12" sm="1">
              <v-sheet rounded="lg" min-height="268"> </v-sheet>
            </v-col> -->

            <v-col cols="12" sm="8">
              <v-sheet min-height="70vh" rounded="lg" class="blog">
                <!--  -->
                <p v-if="updatedAt === ''">登録日：{{ this.createdAt }}</p>
                <p v-else>更新日：{{ this.updateAt }}</p>
                <div class="py-2"></div>
                <h2 class="grey">{{ info.fields.title }}</h2>
                <div v-if="info.fields.image" class="py-2"></div>
                <v-img
                  v-if="info.fields.image && width > 500"
                  width="100%"
                  height="400px"
                  :src="info.fields.image.fields.file.url"
                ></v-img>
                <v-img
                  v-if="info.fields.image && width < 500"
                  width="100%"
                  :src="info.fields.image.fields.file.url"
                ></v-img>
                <br />
                <div v-html="info.fields.text"></div>
              </v-sheet>
            </v-col>

            <v-col cols="12" sm="4">
              <v-sheet rounded="lg" min-height="268">
                <h3 class="blog">ライター情報</h3>
                <v-row justify="center">
                  <v-avatar size="128" text-center>
                    <img :src="require('@/assets/logo_k.png')" :width="100" />
                  </v-avatar>
                </v-row>
                <v-row justify="center">
                  <div v-html="writer[0].writer"></div>
                  <div class="blog" v-html="writer[0].discription"></div>
                </v-row>
              </v-sheet>
              <div class="text-center margin">
                <a
                  href="https://px.a8.net/svt/ejp?a8mat=3NPIDJ+9IC9MA+4GDM+63H8H"
                  rel="nofollow"
                >
                  <img
                    class="scale-img"
                    border="0"
                    width="340"
                    height="340"
                    alt=""
                    src="https://www25.a8.net/svt/bgt?aid=221219911575&wid=001&eno=01&mid=s00000020785001024000&mc=1"
                /></a>
                <img
                  border="0"
                  width="1"
                  height="1"
                  src="https://www19.a8.net/0.gif?a8mat=3NPIDJ+9IC9MA+4GDM+63H8H"
                  alt=""
                />
              </div>
            </v-col>
          </v-row>
        </div>
        <div class="py-3"></div>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import { defineComponent, computed, reactive } from "vue";
import { useHead } from "@vueuse/head";
import { createClient } from "@/plugins/contentful";
import loding from "../myLoding.vue";

export default defineComponent({
  components: {
    loding,
  },
  data() {
    return {
      width: 0,
      info: [],
      infos: [],
      url: "",
      show: false,
      loding: true,
      writer: [
        {
          writer: "<p>坂東泰地</p>",
          discription:
            "<p>当サービスの開発、運営者。</p><p>「妻」「韓国語」「プログラミング」を愛する25歳で、韓国 × webアプリ開発の専門家。<p>韓国好きな人々のために、日々奮闘中...</p>",
        },
      ],
      updateAt: "",
      createdAt: "",
    };
  },
  methods: {
    // ヘッダー情報
    setup() {
      const siteData = reactive({
        title: this.info.fields.seoTitle,
        description: this.info.fields.seoDiscription,
      });
      useHead({
        // Can be static or computed
        title: computed(() => siteData.title),
        meta: [
          {
            name: "description",
            content: computed(() => siteData.description),
          },
        ],
      });
    },
    // 日付の変更
    dateChange(date) {
      var dt = date;
      var y = dt.getFullYear();
      var m = ("00" + (dt.getMonth() + 1)).slice(-2);
      var d = ("00" + dt.getDate()).slice(-2);
      var result = y + "/" + m + "/" + d;
      return result;
    },
  },
  // テンプレートの取得取得
  async created() {
    this.width = window.innerWidth;
    // 情報取得
    const url = this.$route.path.split("/");
    this.url = url[2];
    await createClient()
      .getEntries({
        content_type: "blog",
        "fields.url": this.url,
      })
      .then((res) => {
        this.loding = false;
        this.info = res.items[0];
        console.log(this.info);
        this.setup();
        const created = new Date(res.items[0].sys.createdAt);
        const updated = new Date(res.items[0].sys.updatedAt);
        this.createdAt = this.dateChange(created);
        this.updateAt = this.dateChange(updated);
      });
  },
});
</script>

<style scoped>
.grey {
  color: #4a4949;
}
.text-grey {
  color: #4a4949;
}

.div {
  width: 100%;
}
.blog {
  padding: 30px;
}

.blog-title {
  margin-left: 40px;
  margin-bottom: 20px;
}

.margin {
  margin-top: 30px;
}
.scale-img {
  width: 340px; /*画像の幅*/
  height: 340px; /*画像の高さ*/
  transition-duration: 0.5s; /*変化の時間*/
}
.scale-img:hover {
  transform: scale(1.1, 1.1); /*画像の拡大*/
  cursor: pointer; /*カーソルをポインターにする*/
}
</style>

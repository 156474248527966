<template>
  <v-app class="bg-img">
    <!-- <div class="py-3 bg-img"></div> -->
    <v-container>
      <v-row>
        <v-col class="center">
          <v-tabs v-model="tab" color="blue" class="ma-4 text-center center">
            <v-tab value="1">スマホ</v-tab>
            <v-tab value="2">パソコン</v-tab>
          </v-tabs>
        </v-col>
      </v-row>

      <v-window v-model="tab">
        <v-window-item value="1">
          <h2 class="grey-color">スマホカルテ</h2>
          <spChart />
        </v-window-item>
        <v-window-item value="2">
          <h2 class="grey-color">パソコンカルテ</h2>
          <pcChart />
        </v-window-item>
      </v-window>
      <!-- <p>
        韓国語のタイピングはもう慣れましたか？<br />
        韓国語のタイピングが早くなれば仕事でバリバリ働ける!<br />
        カルテで自己分析しながら正確性や速度を上げていきましょう！
      </p> -->
      <div class="py-5"></div>
    </v-container>
  </v-app>
</template>

<script>
import pcChart from "../Chart/pcChart";
import spChart from "../Chart/spChart";

export default {
  components: {
    pcChart,
    spChart,
  },
  data: () => ({
    tab: null,
  }),
  methods: {},
};
</script>

<style scoped>
.grey-color {
  margin-bottom: 20px;
}

/* #center {
  justify-content: center !important;
} */

.center {
  justify-content: center;
}
</style>

<template>
  <v-app class="bg-img">
    <div class="py-3"></div>
    <v-container>
      <div class="py-3"></div>
      <v-card width="100%" class="mx-auto mt-4 margin" cols="12" sm="4">
        <v-card-title class="card-title">
          <h3 class="display-2">問題登録</h3>
        </v-card-title>
        <v-card-text>
          <v-text-field
            label="SEOタイトル"
            v-model="seo_title"
            required
            :counter="30"
          />
          <v-textarea
            label="SEO_description"
            v-model="seo_description"
            :counter="120"
            required
          />
          <v-text-field label="タイトル" v-model="title" required />
          <v-textarea label="description" v-model="description" required />
          <v-text-field
            label="カウント数"
            v-model="count"
            type="number"
            required
          />
          <v-select
            label="タイピングカテゴリー"
            v-model="typing_category"
            :items="[1, 2, 3, 4, 5, 6, 7]"
            required
          ></v-select>
          <p>
            1: check<br />
            2: K-POP<br />
            3: love<br />
            4: work<br />
            5: life<br />
            6: TrilingualTomi , beginner<br />
            7: TrilingualTomi , intermediate<br />
          </p>
          <br />
          <br />
          <h3>タイピング問題</h3>
          <br />
          <div v-for="(question, i) in questions" :key="i">
            <p>{{ i + 1 }}問目</p>
            <v-text-field
              :label="'korean' + [i + 1]"
              v-model="questions[i].korean"
            />
            <v-text-field
              :label="'japanese' + [i + 1]"
              v-model="questions[i].japanese"
            />
          </div>
          <br />
          <h3>サイト紹介</h3>
          <br />
          <v-select
            label="サイト紹介"
            v-model="site_select"
            :items="['マリシャ', 'トミ', 'なし']"
          ></v-select>
          <v-text-field label="詳細ページのURL" v-model="site_url" />
          <v-text-field label="詳細ページのタイトル" v-model="site_title" />
          <!-- <v-textarea label="最終文章" v-model="json" /> -->
          <v-card-actions>
            <v-btn color="blue" @click="register">問題を登録する</v-btn>
          </v-card-actions>
        </v-card-text>
      </v-card>
    </v-container>
  </v-app>
</template>
<script>
export default {
  data: () => ({
    questions: [
      {
        id: 1,
        korean: "",
        japanese: "",
      },
      {
        id: 2,
        korean: "",
        japanese: "",
      },
      {
        id: 3,
        korean: "",
        japanese: "",
      },
      {
        id: 4,
        korean: "",
        japanese: "",
      },
      {
        id: 5,
        korean: "",
        japanese: "",
      },
      {
        id: 6,
        korean: "",
        japanese: "",
      },
      {
        id: 7,
        korean: "",
        japanese: "",
      },
      {
        id: 8,
        korean: "",
        japanese: "",
      },
      {
        id: 9,
        korean: "",
        japanese: "",
      },
      {
        id: 10,
        korean: "",
        japanese: "",
      },
    ],
    questions_json: [],
    seo_title: "",
    seo_description: "",
    title: "",
    description: "",
    site_introduction: [],
    site_json: null,
    site_select: "なし",
    site_url: "",
    site_title: "",
    count: 0,
    typing_category: null,
    json: [],
    token: "",
    userApiUrl: process.env.VUE_APP_API_BASE_URL,
    apiUrl: "https://api.korean-typing.com/api/",
    // apiUrl: process.env.VUE_APP_API_BASE_URL,
  }),
  created: function () {
    this.getUser();
  },
  methods: {
    getUser() {
      if (localStorage.getItem("loginData") === null) {
        this.$router.push({ path: "/" });
        return;
      }
      const obj = localStorage.getItem("loginData");
      const loginData = JSON.parse(obj);
      this.token = loginData.token;
      this.axios
        .get(this.userApiUrl + "user", {
          headers: {
            Authorization: "Bearer " + this.token,
            "Content-type": "application / json",
            Accept: "application/json,",
            data: {},
          },
        })
        .then((res) => {
          if (
            res.data.name !== "たいち" ||
            res.data.email !== "taichi.cnblue@gmail.com"
          ) {
            this.$router.push({ path: "/" });
          }
        });
    },
    register() {
      this.checkSite();
      this.createJson();
      console.log(this.typing_category);
      this.axios
        .post(
          this.apiUrl + "question/create",
          {
            typing_category: Number(this.typing_category),
            seo_title: this.seo_title,
            seo_description: this.seo_description,
            title: this.title,
            description: this.description,
            question: this.questions_json,
            site_introduction: this.site_json,
            count: Number(this.count),
          },
          {
            headers: {
              Authorization: "Bearer " + this.token,
              "Content-type": "application/json",
              Accept: "application/json,",
            },
          }
        )
        .then((res) => {
          console.log(res.data);
          alert("登録が完了しました");
        })
        .catch((e) => {
          console.log(e);
          alert("問題が発生しました");
        });
    },
    // getQuestion() {
    //   this.axios
    //     .get(this.apiUrl + "question/show/" + 9)
    //     .then((res) => {
    //       console.log(res.data);
    //       let question_json = JSON.parse(res.data.question);
    //       let site_json = JSON.parse(res.data.site_introduction);
    //       console.log(question_json);
    //       console.log(site_json);
    //     })
    //     .catch((e) => {
    //       console.log(e);
    //     });
    // },
    checkSite() {
      if (this.site_select === "なし") {
        this.site_introduction = null;
      }
      if (this.site_select === "マリシャ") {
        this.site_introduction = {
          site: "marisha韓国語勉強ブログ",
          url: "https://marisha39.com/",
          question_url: this.site_url,
          title: this.site_title,
        };
      }
      if (this.site_select === "トミ") {
        this.site_introduction = {
          site: "トリリンガルのトミ韓国語講座：無料なのに有料以上！",
          url: "https://trilingual.jp/",
          question_url: this.site_url,
          title: this.site_title,
        };
      }
    },
    createJson() {
      const question_check = [];
      for (let i = 0; i < this.questions.length; i++) {
        if (this.questions[i].korean !== "") {
          question_check.push({
            id: i + 1,
            korean: this.questions[i].korean,
            japanese: this.questions[i].japanese,
          });
        }
      }
      this.questions_json = JSON.stringify(question_check);
      if (this.site_introduction !== null) {
        this.site_json = JSON.stringify(this.site_introduction);
      }
    },
    // login: async function () {
    //   if (this.email === "" || this.password === "") {
    //     alert("入力項目はすべて必須項目です");
    //     return;
    //   }
    //   axios.withCredentials = true;
    //   await axios
    //     .post(this.apiUrl + "login", {
    //       email: this.email,
    //       password: this.password,
    //     })
    //     .then((res) => {
    //       console.log(res.data);
    //       if (res.data.status_code === 200) {
    //         alert("ログインに成功しました!");
    //         const token = res.data.token.split("|");
    //         const loginData = {
    //           userID: res.data.id,
    //           token: token[1],
    //         };
    //         var obj = JSON.stringify(loginData);
    //         localStorage.setItem("loginData", obj);
    //         location.href = "/";
    //       } else if (
    //         res.data.status_code === 500 ||
    //         res.data.status_code === 201
    //       ) {
    //         alert("ユーザが見つかりません");
    //         this.email = "";
    //         this.password = "";
    //       } else {
    //         alert("認証に失敗しました");
    //         this.email = "";
    //         this.password = "";
    //       }
    //     })
    //     .catch((error) => {
    //       if (error.response.request.status === 422) {
    //         alert("入力項目が空です");
    //       }
    //       if (error.response.request.status === 500) {
    //         alert("ユーザが見つかりませんでした");
    //         this.email = "";
    //         this.password = "";
    //       }
    //     });
    // },
  },
};
</script>
<style lang="scss" scoped>
.margin {
  margin-bottom: 30px;
}
</style>

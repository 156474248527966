<template>
  <v-app class="bg-img">
    <div class="py-3"></div>
    <v-container>
      <v-card width="350px" class="mx-auto mt-4" cols="12" sm="4">
        <v-card-title class="card-title">
          <h3 class="display-2">メール認証</h3>
        </v-card-title>
        <v-card-text>
          <v-form>
            <p>メール認証</p>
            <!-- <br />
            <v-text-field
              prepend-icon="mdi-account-circle"
              label="メールアドレス"
              type="email"
              v-model="email"
            /> -->
            <v-card-actions>
              <v-btn color="blue" @click="reSendEmail">メール認証</v-btn>
            </v-card-actions>
          </v-form>
        </v-card-text>
      </v-card>
      <p v-if="alert !== ''" v-html="alert" class="error-text text-center"></p>
    </v-container>
  </v-app>
</template>
<script>
export default {
  data: () => ({
    email: "",
    emailVerified: "",
    alert: "",
    token: "",
    apiUrl: process.env.VUE_APP_API_BASE_URL,
  }),
  created: function () {
    this.getMyInfo();
  },
  methods: {
    getMyInfo() {
      const obj = localStorage.getItem("loginData");
      const loginData = JSON.parse(obj);
      console.log(loginData);
      this.token = loginData.token;
      if (loginData.emailVerified === "OK") {
        alert("メール認証は完了しています");
        location.href = "/";
        return;
      }
      this.axios
        .get(this.apiUrl + "user", {
          headers: {
            Authorization: "Bearer " + this.token,
            "Content-type": "application/json",
            Accept: "application/json,",
            data: {},
          },
        })
        .then((res) => {
          console.log(res.data);
          this.email = res.data.email;
        })
        .catch((e) => {
          console.log(e);
        });
    },
    async reSendEmail() {
      await this.axios
        .post(
          this.apiUrl + "email/verification-notification",
          {
            email: this.email,
          },
          {
            headers: {
              Authorization: "Bearer " + this.token,
              "Content-type": "application/json",
              Accept: "application/json,",
              data: {},
            },
          }
        )
        .then((res) => {
          console.log(res);
          this.alert =
            "メール送信が完了しました。<br>10分以上待っても届かない場合は迷惑フォルダもご確認ください。";
        })
        .catch((e) => {
          console.log(e);
        });
    },
  },
};
</script>
<style lang="scss" scoped>
.error-text {
  margin-top: 20px;
  color: darkblue;
  font-weight: bold;
}
</style>

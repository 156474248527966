<template>
  <v-app class="bg-img">
    <div class="py-5 bg-img"></div>
    <!-- 問題 -->
    <v-container>
      <v-row class="text-center">
        <v-col>
          <div v-if="show && !completed">
            <h2 class="grey-color">
              {{ textIndex + 1 }} / {{ this.questions.length }}
            </h2>
            <p class="font-size-big">
              <span class="text-blue" v-html="this.ClearText"></span
              ><span class="grey-color">{{ currentText }}</span>
            </p>
            <p class="grey-color">
              {{ this.questions[this.randomMath[this.textIndex]].translation }}
            </p>
            <br />
            <v-row v-if="this.url == 'kpop'">
              <v-col
                class="grey-color"
                v-if="
                  this.questions[this.randomMath[this.textIndex]].music !==
                  undefined
                "
                >曲名:{{
                  this.questions[this.randomMath[this.textIndex]].music
                }}</v-col
              >
              <v-col
                class="grey-color"
                v-if="
                  this.questions[this.randomMath[this.textIndex]].lyrics !==
                  undefined
                "
                >作詞:{{
                  this.questions[this.randomMath[this.textIndex]].lyrics
                }}</v-col
              >
            </v-row>
            <!-- タイムアタック -->
            <!-- <div id="timer" v-if="this.url == 'time-atack'">
              <v-container>
                <h4>
                  <span>{{ minute }}</span
                  >:<span>{{ second }}</span>
                </h4>
                <v-progress-linear
                  :color="color"
                  :style="styles"
                  height="20"
                  striped
                  rounded
                ></v-progress-linear>
              </v-container>
            </div> -->
            <!-- 入力窓 -->
            <v-container v-if="this.autofoucus == true">
              <v-textarea
                v-if="this.DisplayWidth > 640"
                :value="input"
                v-on:keydown="onKeyDown"
                v-on:keyup="onKeyUp"
                filled
                autofocus
                class="input bg-color"
                height="600"
                @input="onInputChange"
              ></v-textarea>
              <input
                v-else
                :value="input"
                filled
                autofocus
                class="bg-color-input"
                height="200"
                @input="onInputChange"
              />
              <!-- キーボード -->
              <SimpleKeyboard
                v-if="this.DisplayWidth > 640 && keySuport"
                @onChange="onChange"
                @onKeyPress="onKeyPress"
                :input="input"
                :hangul="this.hangulChange"
                :typing="typingText"
                :keydown="keydown"
                :keyup="keyup"
                :shiftToggle="shiftToggle"
              />
            </v-container>
          </div>
        </v-col>
      </v-row>

      <div v-if="show && completed">
        <v-container>
          <v-row class="text-center">
            <v-col cols="12">
              <v-card>
                <v-card-title class="card-title">結果</v-card-title>
                <p>お疲れ様でした！</p>
                <br />
                <div v-if="showComp == false">
                  <loding />
                </div>
                <!-- PCの結果 -->
                <div v-if="showComp == true && this.DisplayWidth > 640">
                  <!-- 一言解説 -->
                  <p v-if="level == 'D-' || level == 'D' || level == 'D+'">
                    あなたのタイピングはまだイマイチかも...<br />
                    ミスを減らせばもっといい結果を出せるはず！！！
                  </p>
                  <p v-if="level == 'C-' || level == 'C' || level == 'C+'">
                    あなたのタイピングは日本人の中では早い方です！<br />
                    もう少しで韓国人並みになれるかも！！！
                  </p>
                  <p v-if="level == 'B-' || level == 'B' || level == 'B+'">
                    あなたのタイピングは韓国人並み！<br />
                    韓国人とのやり取りでも困らないでしょう！<br />
                    でももっと高みを目指せるはず！
                  </p>
                  <p v-if="level == 'A-' || level == 'A' || level == 'A+'">
                    あなたのタイピングは韓国人でも早い方！<br />
                    もう韓国人にも驚かれる速度です！！！
                  </p>
                  <br />
                  <p>
                    スコア: &nbsp;
                    <span class="text-blue-data">{{ this.score }}</span>
                  </p>
                  <br />
                  <p>
                    レベル:&nbsp;
                    <span class="text-blue-data"> {{ this.level }}</span>
                  </p>
                  <br />
                  <p>
                    入力時間:&nbsp;
                    <span class="text-blue-data"> {{ getTimeStr() }}</span>
                  </p>
                  <br />
                  <p>
                    タイピング数:&nbsp;
                    <span class="text-blue-data"> {{ this.typingNumAll }}</span>
                  </p>
                  <br />
                  <p>
                    ミス入力数:&nbsp;
                    <span class="text-blue-data"> {{ this.missIndex }}</span>
                  </p>
                  <br />
                  <p>
                    WPM:&nbsp;
                    <span class="text-blue-data">{{ this.wpm }}</span>
                  </p>
                  <br />
                  <p>
                    正確率:&nbsp;
                    <span class="text-blue-data"> {{ this.Accuracy }}%</span>
                  </p>
                  <br />
                  <div
                    v-if="this.DisplayWidth > 640 && this.missTyping.length > 0"
                  >
                    <p>
                      <span>苦手キー: </span
                      ><span
                        v-for="(miss, i) in missTyping"
                        :key="i"
                        class="text-blue-data"
                        >{{ miss.key }}
                        <span v-if="i !== missTyping.length - 1">、</span>
                      </span>
                    </p>
                  </div>
                </div>
                <!-- スマホの結果 -->
                <div v-if="showComp == true && this.DisplayWidth < 640">
                  <!-- 一言解説 -->
                  <p v-if="level == 'D-' || level == 'D' || level == 'D+'">
                    あなたのタイピングはまだイマイチかも...<br />
                    ミスを減らせばもっといい結果を出せるはず！！！
                  </p>
                  <p v-if="level == 'C-' || level == 'C' || level == 'C+'">
                    あなたのタイピングは日本人の中では早い方です！<br />
                    もう少しで韓国人並みになれるかも！！！
                  </p>
                  <p v-if="level == 'B-' || level == 'B' || level == 'B+'">
                    あなたのタイピングは韓国人並み！<br />
                    韓国人とのやり取りでも困らないでしょう！<br />
                    でももっと高みを目指せるはず！
                  </p>
                  <p v-if="level == 'A-' || level == 'A' || level == 'A+'">
                    あなたのタイピングは韓国人でも早い方！<br />
                    もう韓国人にも驚かれる速度です！！！
                  </p>
                  <br />
                  <p>
                    スコア: &nbsp;
                    <span class="text-blue-data">{{ this.score }}</span>
                  </p>
                  <br />
                  <p>
                    レベル:&nbsp;
                    <span class="text-blue-data"> {{ this.level }}</span>
                  </p>
                  <br />
                  <p>
                    入力時間:&nbsp;
                    <span class="text-blue-data"> {{ getTimeStr() }}</span>
                  </p>
                </div>

                <!-- <p>得点: {{ this.score }} / {{ this.questions.length }}</p> -->
                <!-- <router-link to="/">ランキングをみる</router-link> -->
                <br />
                <v-card-text v-html="this.introduction"></v-card-text>
                <br />
                <v-row justify="center">
                  <v-col md="4" xs="12">
                    <v-btn color="#4FC3F7" @click="reload">
                      もう一度挑戦する
                    </v-btn>
                  </v-col>
                  <v-col md="4" xs="12">
                    <v-btn color="#4FC3F7" to="/"> Home画面に戻る </v-btn>
                  </v-col>
                  <v-col md="4" xs="12">
                    <!-- ツイッター トリリンガルのトミ -->
                    <!-- スマホの場合 -->
                    <ShareNetwork
                      v-if="url == 'tomi' && DisplayWidth < 640"
                      network="twitter"
                      url="https://korean-typing.com/"
                      :title="
                        '【スマホで挑戦】コリアンタイピング：トリリンガルのトミ「' +
                        this.title +
                        '」で' +
                        this.level +
                        'ランクを獲得しました!'
                      "
                      hashtags="コリアンタイピング,韓国語勉強,韓国語学習,韓国語"
                      twitter-user="taichi_k_web"
                    >
                      <v-btn color="#ffff99" to="/" class="deco-none">
                        twitterでシェアする!
                      </v-btn>
                    </ShareNetwork>
                    <!-- PCの場合 -->
                    <ShareNetwork
                      v-if="url == 'tomi' && DisplayWidth > 640"
                      network="twitter"
                      url="https://korean-typing.com/"
                      :title="
                        '【PCで挑戦】コリアンタイピング：トリリンガルのトミ「' +
                        this.title +
                        '」で' +
                        this.level +
                        'ランクを獲得しました!'
                      "
                      hashtags="コリアンタイピング,韓国語勉強,韓国語学習,韓国語"
                      twitter-user="taichi_k_web"
                    >
                      <v-btn color="#ffff99" to="/" class="deco-none">
                        twitterでシェアする!
                      </v-btn>
                    </ShareNetwork>
                    <!-- ツイッター -->
                    <!-- スマホ -->
                    <ShareNetwork
                      v-if="url !== 'tomi' && DisplayWidth < 640"
                      network="twitter"
                      url="https://korean-typing.com/"
                      :title="
                        '【スマホで挑戦】コリアンタイピング「' +
                        this.title +
                        '」で' +
                        this.level +
                        'ランクを獲得しました!'
                      "
                      hashtags="コリアンタイピング,韓国語勉強,韓国語学習,韓国語"
                      twitter-user="taichi_k_web"
                    >
                      <v-btn color="#ffff99" to="/">
                        twitterでシェアする!
                      </v-btn>
                    </ShareNetwork>
                    <!-- PC -->
                    <ShareNetwork
                      v-if="url !== 'tomi' && DisplayWidth > 640"
                      network="twitter"
                      url="https://korean-typing.com/"
                      :title="
                        '【PCで挑戦】コリアンタイピング「' +
                        this.title +
                        '」で' +
                        this.level +
                        'ランクを獲得しました!'
                      "
                      hashtags="コリアンタイピング,韓国語勉強,韓国語学習,韓国語"
                      twitter-user="taichi_k_web"
                    >
                      <v-btn color="#ffff99" to="/">
                        twitterでシェアする!
                      </v-btn>
                    </ShareNetwork>
                  </v-col>
                </v-row>
                <div class="py-3"></div>
              </v-card>
            </v-col>
          </v-row>
        </v-container>
      </div>
      <div class="py-5"></div>

      <!-- ダイアログ -->
      <v-row justify="center">
        <v-dialog v-model="dialog" width="600px" persistent>
          <v-card>
            <v-card-title v-if="this.url == 'check'" class="card-title">
              腕試しレベルチェック
            </v-card-title>
            <v-card-text class="grey-color">
              キーボードは韓国語入力モードに変更しましたか？<br />
              準備できたらスタートしましょう！<br /><br />
              ※スマホだと正常に動作しない場合があります。<br />
              ご了承ください。
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-switch
                v-model="keySuport"
                label="キーボードサポート"
                color="blue"
                inset
                v-if="this.DisplayWidth > 640"
              ></v-switch>
              <div v-if="!show">
                <loding />
              </div>
              <div v-else>
                <v-btn @click="gameStart" color="blue"> スタート！ </v-btn>
              </div>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-row>
      <!-- <props :objects="objects"> </props> -->
    </v-container>
  </v-app>
</template>

<script>
import { defineComponent, computed, reactive } from "vue";
import { useHead } from "@vueuse/head";
import { createClient } from "@/plugins/contentful";
import SimpleKeyboard from "../SimpleKeyboard";
import Hangul from "@/plugins/korean";
import "@mdi/font/css/materialdesignicons.css";
import loding from "../myLoding.vue";
// import hangulChange from "@/plugins/hangulChange";
// import { typingKey } from "@/plugins/typingKey";

export default defineComponent({
  name: "App",
  components: {
    SimpleKeyboard,
    loding,
  },
  computed: {
    // タイムアタック用 バインドするスタイルを生成
    // styles() {
    //   return {
    //     "background-color": this.color + "!important",
    //     width: this.width + "%",
    //   };
    // },
    // 現在のクイズ内容を自動的に取得できる疑似変数
    currentText: function () {
      this.koreanChange(); // 次のキーボードを光らせる
      return this.questions[this.randomMath[this.textIndex]].origin;
    },
    // クイズの解答がすべて完了しているかどうかが分かる疑似変数
    completed() {
      return this.questions.length == this.clearCount;
    },
  },
  data: () => ({
    // コンテントフル
    seoTitle: "",
    seoDescription: "",
    texts: [], // 受け取り窓口
    title: "", // お題のタイトル
    introduction: "", // ブログ紹介文
    questions: [], // 問題格納場所
    // タイピングアプリ
    textIndex: 0, //現在のクイズ番号を保持
    input: "", //入力したキーボード
    buttons: [], //画面上のキーボード
    typingText: "", //入力したキーボード
    url: "", //パラメータ取得
    url2: "", //パラメータ取得
    dialog: true, //ダイアログの表示有無
    autofoucus: false, //スタートしたらキーボード表示
    NowText: 0, // 今入力したい文字
    ClearText: "",
    keySuport: true, // キーボードサポートの有無フラグ
    nowTyping: 0, // 今入力している文字の番号（場所）
    hangulChange: "", // キーボードを光らせるための次の単語ワード
    DisplayWidth: 0, // 画面幅
    oldOriginText: [], // オリジナル問題
    keydown: "",
    keyup: "",
    typingNum: 0, //文字カウント(キーボードサポートのために使用)
    shiftToggle: false, //Shiftを押したかどうか(キーボードサポートのために使用)
    hangulArray: [], //最初の１文字目を韓国語で分けたものの配列
    randomMath: [], // 重複なしのランダムな数字（問題ランダム表示に使用
    show: false,
    showComp: false,
    clearCount: 0,
    // スコア計算式
    missIndex: 0, //失敗した数
    missTyping: [],
    score: 0,
    level: "",
    originLength: 0, //問題の文字数(空欄込み)
    inputTypeLength: 0, //入力文字数
    typingNumAll: 0, //タイピングした文字のカウント
    wpm: 0, //ミスも含めた入力文字数の一分に換算したときの入力できるキー数
    Accuracy: 0,
    // ストップウオッチ
    status: "clear",
    time: 0,
    startTime: null,
    stopTime: 0,
    minutes: 0,
    seconds: 0,
    typingTime: 0, // 秒数
    // タイムアタック用
    // minute: 1,
    // second: 0,
    // "background-color": "red",
    // width: 100,
    // color: "#0d6efd",
    // score: 0,
  }),
  async created() {
    // 画面幅取得
    this.DisplayWidth = window.innerWidth;
    // URLからパラメータ取得
    const url = this.$route.path.split("/");
    this.url = url[2];
    this.url2 = url[3];
    // 画面トップに移動
    this.moveToTop();
    await createClient()
      .getEntries({
        content_type: this.url,
        "fields.url": this.url2,
      })
      .then((res) => {
        this.texts = res.items;
        this.title = this.texts[0].fields.title;
        this.introduction = this.texts[0].fields.Introduction;
        this.seoTitle = this.texts[0].fields.seoTitle;
        this.seoDescription = this.texts[0].fields.seoDiscription;
        // 取得した内容を連想配列に保存
        if (this.texts[0].fields.question1 !== undefined) {
          // オリジナル
          let text1 = this.texts[0].fields.question1;
          // 翻訳
          let translation = this.texts[0].fields.translation1;
          // kpop用
          let lyrics = this.texts[0].fields.lyrics1;
          let music = this.texts[0].fields.music1;
          // ＝＝＝連想配列に格納＝＝＝
          this.questions.push({
            origin: text1,
            translation: translation,
            lyrics: lyrics,
            music: music,
          });
        }
        if (this.texts[0].fields.question2 !== undefined) {
          let text1 = this.texts[0].fields.question2;
          let translation = this.texts[0].fields.translation2;
          let lyrics = this.texts[0].fields.lyrics2;
          let music = this.texts[0].fields.music2;
          this.questions.push({
            origin: text1,
            translation: translation,
            lyrics: lyrics,
            music: music,
          });
        }
        if (this.texts[0].fields.question3 !== undefined) {
          let text1 = this.texts[0].fields.question3;
          let translation = this.texts[0].fields.translation3;
          let lyrics = this.texts[0].fields.lyrics3;
          let music = this.texts[0].fields.music3;
          this.questions.push({
            origin: text1,
            translation: translation,
            lyrics: lyrics,
            music: music,
          });
        }
        if (this.texts[0].fields.question4 !== undefined) {
          let text1 = this.texts[0].fields.question4;
          let translation = this.texts[0].fields.translation4;
          let lyrics = this.texts[0].fields.lyrics4;
          let music = this.texts[0].fields.music4;
          this.questions.push({
            origin: text1,
            translation: translation,
            lyrics: lyrics,
            music: music,
          });
        }
        if (this.texts[0].fields.question5 !== undefined) {
          let text1 = this.texts[0].fields.question5;
          let translation = this.texts[0].fields.translation5;
          let lyrics = this.texts[0].fields.lyrics5;
          let music = this.texts[0].fields.music5;
          this.questions.push({
            origin: text1,
            translation: translation,
            lyrics: lyrics,
            music: music,
          });
        }
        if (this.texts[0].fields.question6 !== undefined) {
          let text1 = this.texts[0].fields.question6;
          let translation = this.texts[0].fields.translation6;
          let lyrics = this.texts[0].fields.lyrics6;
          let music = this.texts[0].fields.music6;
          this.questions.push({
            origin: text1,
            translation: translation,
            lyrics: lyrics,
            music: music,
          });
        }
        if (this.texts[0].fields.question7 !== undefined) {
          let text1 = this.texts[0].fields.question7;
          let translation = this.texts[0].fields.translation7;
          let lyrics = this.texts[0].fields.lyrics7;
          let music = this.texts[0].fields.music7;
          this.questions.push({
            origin: text1,
            translation: translation,
            lyrics: lyrics,
            music: music,
          });
        }
        if (this.texts[0].fields.question8 !== undefined) {
          let text1 = this.texts[0].fields.question8;
          let translation = this.texts[0].fields.translation8;
          let lyrics = this.texts[0].fields.lyrics8;
          let music = this.texts[0].fields.music8;
          this.questions.push({
            origin: text1,
            translation: translation,
            lyrics: lyrics,
            music: music,
          });
        }
        if (this.texts[0].fields.question9 !== undefined) {
          let text1 = this.texts[0].fields.question9;
          let translation = this.texts[0].fields.translation9;
          let lyrics = this.texts[0].fields.lyrics9;
          let music = this.texts[0].fields.music9;
          this.questions.push({
            origin: text1,
            translation: translation,
            lyrics: lyrics,
            music: music,
          });
        }
        if (this.texts[0].fields.question10 !== undefined) {
          let text1 = this.texts[0].fields.question10;
          let translation = this.texts[0].fields.translation10;
          let lyrics = this.texts[0].fields.lyrics10;
          let music = this.texts[0].fields.music10;
          this.questions.push({
            origin: text1,
            translation: translation,
            lyrics: lyrics,
            music: music,
          });
        }
        this.setup();
        this.questionTypeCount();
        this.getTestAPI();
      });
    for (let i = 0; i < this.questions.length; i++) {
      this.oldOriginText.push(this.questions[i].origin);
    }
    this.randomNum();
    this.show = true;
  },
  methods: {
    // ヘッダー情報
    setup() {
      const siteData = reactive({
        title: this.seoTitle,
        description: this.seoDescription,
      });
      useHead({
        // Can be static or computed
        title: computed(() => siteData.title),
        meta: [
          {
            name: "description",
            content: computed(() => siteData.description),
          },
        ],
      });
    },
    onChange(input) {
      this.input = input;
    },
    onKeyPress(button) {
      this.buttons.push(button);
    },
    onInputChange(input) {
      this.input = input.target.value;
      this.typingText = input.data;
      let originText = this.questions[this.randomMath[this.textIndex]].origin; // オリジナル文章
      let firstText = originText.charAt(0); // オリジナル文章の最初の1文字目(1文字目は正解するごとに削除されていく)
      let firstHangul = Hangul.disassemble(firstText); // 最初の１文字目を韓国語で分けたものの配列
      let nowTyping = 0;
      if (this.typingText == firstHangul[nowTyping]) {
        nowTyping++;
        if (this.typingText == firstText) {
          nowTyping = 0;
        }
      }
      // スマホで入力欄が空にならないバグ修正
      this.inputClear();
      this.clear();
    },
    // 重複なしのランダムな数字(問題ランダム表示に使用)
    randomNum() {
      let randoms = [];
      let min = 0;
      let max = this.questions.length - 1;
      /** 重複チェックしながら乱数作成 */
      for (let i = min; i <= max; i++) {
        var tmp = intRandom(min, max);
        if (!randoms.includes(tmp)) {
          randoms.push(tmp);
        } else {
          i = i - 1;
        }
      }
      /** min以上max以下の整数値の乱数を返す */
      function intRandom(min, max) {
        return Math.floor(Math.random() * (max - min + 1)) + min;
      }
      this.randomMath = randoms;
    },
    onKeyDown(e) {
      let key = e.key;
      this.typingNumAll++;
      if (this.hangulChange == key) {
        this.typingNum++;
        this.koreanChange();
      } else if (key == "Shift" && this.hangulChange == "{shiftleft}") {
        this.typingNum++;
        this.koreanChange();
      } else if (
        (this.hangulChange !== key && key == "Backspace") ||
        key == "Enter" ||
        this.hangulChange == "{shiftleft}" ||
        !key.trim()
      ) {
        this.koreanChange();
      } else {
        this.missIndex++;
        this.missTyping.push(this.hangulChange);
      }
      // ただshiftおしたとき
      if (key == "Shift") {
        this.shiftToggle = true;
        this.keydown = key;
      } else {
        this.keydown = key;
      }
    },
    onKeyUp(e) {
      let keycode = e.key;
      if (keycode == "Shift") {
        this.shiftToggle = false;
        this.keyup = "Shift";
      } else {
        this.keyup = keycode;
      }
    },
    clear() {
      let originText = this.questions[this.randomMath[this.textIndex]].origin; // オリジナル文章
      let firstText = originText.charAt(0); // オリジナル文章の最初の1文字目
      let SecondText = "";
      // スマホでタイピングが反応しない時のバグ修正
      // 原因：スマホで入力すると、this.typingTextが2文字になることがあり、firstTextと一致しなくなっていた
      if (this.DisplayWidth < 640 && this.typingText.length == 2) {
        SecondText = this.typingText.charAt(1);
        // 次が空欄の場合もthis.typingTextが2文字になるため、修正
        if (SecondText == firstText && SecondText.trim()) {
          this.NowText++; // 今の文字を+1
          this.questions[this.randomMath[this.textIndex]].origin =
            originText.slice(1); // オリジナル文章を１文字消す
          this.ClearText += SecondText; // 正解した文章を作成（青色の文字を追加するため）
          this.koreanChange(); // 次のキーボードを光らせ
          this.inputClear(); // スマホで入力欄が空にならないバグ
          this.typingNum = 0;
        }
        // 入力した文字と問題のテキストが一致している場合
      } else if (this.typingText == firstText) {
        this.NowText++; // 今の文字を+1
        this.questions[this.randomMath[this.textIndex]].origin =
          originText.slice(1); // オリジナル文章を１文字消す
        this.ClearText += this.typingText; // 正解した文章を作成（青色の文字を追加するため）
        this.koreanChange(); // 次のキーボードを光らせる
        this.inputClear(); // スマホで入力欄が空にならないバグ
        this.typingNum = 0;
        // もし、間違っていた、かつ、タイプ的にはあっていた場合
      } else if (
        this.typingText !== firstText &&
        this.typingNum == this.hangulArray.length
      ) {
        this.typingNum = 0;
        this.koreanChange();
      }
      //次の文字が空なら
      if (!originText.charAt(0).trim()) {
        this.questions[this.randomMath[this.textIndex]].origin =
          originText.slice(1); // オリジナル文章を１文字消す
        this.ClearText += "<span>&nbsp;</span>"; // 青文字の方も１文字開ける
        this.koreanChange(); // 次のキーボードを光らせる
        this.typingNum = 0;
      }
      // もし問題文が空なら(全問正解したら) または 入力された文字と問題文が一致していたら または 空欄を消した問題分と一致していたら
      if (
        this.questions[this.randomMath[this.textIndex]].origin.length <= 0 ||
        this.input.indexOf(
          this.questions[this.randomMath[this.textIndex]].origin
        ) > -1 ||
        this.input ==
          this.questions[this.randomMath[this.textIndex]].origin.replace(
            /\s+/g,
            ""
          )
      ) {
        // 中身を空にする
        this.input = "";
        this.buttons = [];
        this.typingText = "";
        this.NowText = 0;
        this.ClearText = "";
        this.clearCount++;
        this.textIndex++;
        // 問題全て完了した場合
        if (this.completed) {
          this.stopWatchStop();
          this.missKey();
          this.CountTypingTime();
          // スコア計算
          this.countWPM();
          this.accuracyCount();
          this.scoreCheck();
          this.checkLevel();
          this.showComp = true;
        }
      }
    },
    // ゲームスタート
    gameStart() {
      this.dialog = false;
      this.autofoucus = true;
      if (this.keySuport == true) {
        this.koreanChange();
      }
      // タイムアタックなら
      if (this.url == "time-atack") {
        this.timeStart();
        // 1秒ごとに処理が走る
        this.interval = setInterval(this.timeStart, 1000);
      } else {
        this.stopWatchStart();
      }
    },
    // スクロールでトップに移動
    moveToTop() {
      document.getElementById("page-top").scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    },
    // ストップウォッチ
    // スタート
    stopWatchStart() {
      this.status = "start";
      if (this.startTime === null) {
        this.startTime = Date.now();
      }
      const checkTime = () => {
        this.time = Date.now() - this.startTime + this.stopTime;
      };
      this.timer = setInterval(checkTime, 10);
    },
    // ストップ
    stopWatchStop() {
      if (this.timer) {
        clearInterval(this.timer);
      }
      this.status = "stop";
      this.startTime = null;
      this.stopTime = this.time;
    },
    // リセット
    reset() {
      this.stop();
      this.status = "clear";
      this.time = 0;
      this.startTime = null;
      this.stopTime = 0;
    },
    getTimeStr() {
      // this.time is milliseconds
      // 1秒 = 1000ミリ秒
      // 1分 = 60 * 1000ミリ秒
      // 1時間 = 60 * 60 * 1000ミリ秒
      // let milliseconds = this.time % 1000;
      let seconds = Math.floor((this.time / 1000) % 60);
      let minutes = Math.floor((this.time / (60 * 1000)) % 60);
      // let hours = Math.floor(this.time / (60 * 60 * 1000));
      // let millisecondsMultiplyTen = Math.floor(milliseconds / 10);

      this.seconds = seconds;
      this.minutes = minutes;

      // millisecondsMultiplyTen = ("0" + millisecondsMultiplyTen).slice(-2);
      // hours = hours < 100 ? ("0" + hours).slice(-2) : hours;

      return `${minutes}分${seconds}秒`;
      // ${hours}:
      // .${millisecondsMultiplyTen}
    },
    // タイムアタック (使っていない)
    // timeStart() {
    //   // プログレスの減り具合計算
    //   // レベル別で時間を分けるべき
    //   this.width -= 100 / 60;
    //   // プログレスの色変更
    //   if (this.second == 40) {
    //     this.color = "orange";
    //   } else if (this.second == 21 && this.minute == 0) {
    //     this.color = "yellow";
    //   } else if (this.second == 11 && this.minute == 0) {
    //     this.color = "red";
    //   } else if (this.second == 1 && this.minute == 0) {
    //     this.width = 0;
    //     // 失敗数を足す
    //     this.missIndex++;
    //     // 次の問題に行く
    //     this.textIndex++;
    //     this.timeReSet();
    //   }
    //   // タイマーの時間変更
    //   if (this.second !== 0) {
    //     this.second--;
    //     return;
    //   }
    //   if (this.minute !== 0) {
    //     this.minute--;
    //     this.second = 59;
    //     return;
    //   }
    // },
    // リセット (使っていない)
    // timeReSet() {
    //   this.width = 100;
    //   this.color = "#0d6efd";
    //   this.mitute = 0;
    //   this.second = 60;
    // },
    // リロード
    reload() {
      location.reload();
    },
    // ①入力時間
    CountTypingTime() {
      this.typingTime = Math.floor(this.time / 1000);
    },
    // ②入力文字数(ミスも含めた半角英数字) this.typingNumAll
    // ③ミス入力数(半角英数字のミス入力したキー数) this.missIndex
    // ④WPM(ミスも含めた入力文字数の一分に換算したときの入力できるキー数。多分これも切り捨て)
    countWPM() {
      let oneMinutesTyping = this.typingTime / 60;
      let wpm = this.typingNumAll / oneMinutesTyping;
      this.wpm = Math.floor(wpm);
    },
    // 正確率(正確に入力したキー数（①－②）÷入力文字数（①）×100)
    // 正答率(正しいタイプ数 ÷ 問題数 × 100)
    accuracyCount() {
      // 正確に入力したキー数
      let AccuracyTyping = this.typingNumAll - this.missIndex;
      let Accuracy = (AccuracyTyping / this.typingNumAll) * 100;
      this.Accuracy = Math.floor(Accuracy);
    },
    // 最終的なスコア(WPM×（正確率÷100)の3乗)
    scoreCheck() {
      // PCの場合
      if (this.DisplayWidth > 640) {
        let Accuracy = this.Accuracy / 100;
        let Accuracy3 = Accuracy * Accuracy * Accuracy;
        let scoreDecimal = this.wpm * Accuracy3;
        let scoreInteger = Math.floor(scoreDecimal);
        this.score = scoreInteger;
      }
      if (this.DisplayWidth < 640) {
        // 時間
        // let typingTime = this.minutes * 60 + this.seconds;
        let typingTime = Math.floor(this.time / 1000);
        let minutes = typingTime / 60;
        // 文字数
        // let textLength = 0;
        // for (let i = 0; i < this.questions.length; i++) {
        //   textLength += this.questions[i].origin.length;
        // }
        //1分間にどれだけタイピングできるか
        // 例 120文字 60秒 = 120
        // 600 180 = 200
        // 適当な数(スマホだと難しくなりすぎるから)
        let propernessNumber = 100;
        this.score = Math.floor(this.originLength / minutes + propernessNumber);
      }
    },
    checkLevel() {
      if (this.score < 21) {
        this.level = "E-";
      } else if (this.score < 38) {
        this.level = "E";
      } else if (this.score < 55) {
        this.level = "E+";
      } else if (this.score < 72) {
        this.level = "D-";
      } else if (this.score < 89) {
        this.level = "D";
      } else if (this.score < 106) {
        this.level = "D+";
      } else if (this.score < 123) {
        this.level = "C-";
      } else if (this.score < 140) {
        this.level = "C";
      } else if (this.score < 157) {
        this.level = "C+";
      } else if (this.score < 174) {
        this.level = "B-";
      } else if (this.score < 191) {
        this.level = "B";
      } else if (this.score < 208) {
        this.level = "B+";
      } else if (this.score < 225) {
        this.level = "A-";
      } else if (this.score < 242) {
        this.level = "A";
      } else if (this.score < 259) {
        this.level = "A+";
      } else if (this.score < 276) {
        this.level = "S";
      } else if (this.score < 299) {
        this.level = "Good!!!";
      } else if (300 <= this.score) {
        this.level = "コリアンタイピングマスター！！！";
      }
    },
    // キーボードを光らせる
    koreanChange() {
      if (this.keySuport == true) {
        let originText = this.questions[this.randomMath[this.textIndex]].origin; // オリジナル文章
        let firstText = originText.charAt(0); // オリジナル文章の最初の1文字目(1文字目は正解するごとに削除されていく)
        let firstHangul = Hangul.disassemble(firstText); // 最初の１文字目を韓国語で分けたものの配列
        let shiftHangul = ["ㅃ", "ㅉ", "ㄸ", "ㄲ", "ㅆ", "ㅒ", "ㅖ"];
        // shiftが必要かどうかを判定
        for (let i = 0; i < shiftHangul.length; i++) {
          if (firstHangul.indexOf(shiftHangul[i]) !== -1) {
            let num = firstHangul.indexOf(shiftHangul[i]);
            firstHangul.splice(num, 0, "{shiftleft}");
          }
        }
        this.hangulArray = firstHangul;
        if (firstHangul[this.typingNum] == " ") {
          this.hangulChange = "{space}";
        } else if (firstHangul[this.typingNum] == "{shiftleft}") {
          this.hangulChange = "{shiftleft}";
        } else {
          this.hangulChange = firstHangul[this.typingNum];
        }
      }
    },
    // inputを空にする
    inputClear() {
      // this.DisplayWidth < 640 && this.textIndex > 0 && this.NowText == 1
      if (this.DisplayWidth < 640 && this.textIndex > 0 && this.NowText == 0) {
        let num = this.textIndex - 1;
        let OriginText = this.oldOriginText[this.randomMath[num]];
        if (OriginText.charAt(OriginText.length - 1) == this.input.charAt(0)) {
          // this.input = this.input.substring(1);
          this.input = this.input.substring(1);
        }
      }
    },
    // 苦手キーの表示
    // 参考: https://qiita.com/saka212/items/408bb17dddefc09004c8
    missKey() {
      // ミス集をまとめて配列に入れる
      let count = [];
      for (var i = 0; i < this.missTyping.length; i++) {
        var elm = this.missTyping[i];
        count[elm] = (count[elm] || 0) + 1;
      }
      // https://qiita.com/yunkikki/items/6278c9215e6f46c4e55f
      // ミス集を順番に並び変える
      let arr = Object.keys(count).map((e) => ({ key: e, value: count[e] }));
      arr.sort(function (a, b) {
        if (a.value < b.value) return 1;
        if (a.value > b.value) return -1;
        return 0;
      });
      // 5以降は削除
      if (arr.length > 5) {
        this.missTyping = arr.slice(0, 5);
      } else {
        this.missTyping = arr;
      }
    },
    // 問題の全体の文字数
    questionTypeCount() {
      let typeCount = 0;
      for (let i = 0; i < this.questions.length; i++) {
        typeCount += this.questions[i].origin.length;
      }
      this.originLength = typeCount;
    },
    postRanking() {
      this.axios
        .get(this.apiUrl + "sanctum/csrf-cookie")
        .then(() => {
          console.log("トークン初期化成功");
          this.axios
            .post(this.apiUrl + "api/register", {
              name: this.name,
              email: this.email,
              password: this.password,
            })
            .then((res) => {
              console.log(res);
              if (res.data.status_code === 200) {
                alert("会員登録が完了しました。ログインをお願いします");
                location.href = "/login";
              }
            })
            .catch((error) => {
              console.log(error);
              alert("会員登録に失敗しました。再度ご登録をお願いします");
              this.name = "";
              this.email = "";
              this.password = "";
            });
        })
        .catch((error) => console.log(error));
    },
    getTestAPI() {
      console.log("aaa");
    },
  },
});
</script>

<style lang="scss" scoped>
.font-size-big {
  font-size: 35px;
}

.bg-color {
  background-color: white;
}

.bg-color-input {
  background-color: skyblue !important;
  height: 40px;
  width: 80%;
}

.text-blue {
  color: blue;
  font-weight: 600;
}

.text-blue-data {
  color: #027fff;
  font-weight: 600;
}

.miss-text {
  color: rgb(255, 85, 0);
  font-size: large;
  font-weight: bold;
}
.deco-none {
  text-decoration: none;
}
</style>

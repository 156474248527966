<template>
  <v-app class="bg-img">
    <div class="py-3"></div>
    <v-container>
      <div v-if="this.loding == true">
        <loding />
      </div>
      <div v-else>
        <v-row justify="center">
          <v-col md="12" cols="12">
            <h2 class="text-center">{{ this.title }}</h2>
          </v-col>
          <v-col md="6" cols="12">
            <v-card
              v-for="(content, i) in contents"
              :key="i"
              class="text-left card-margin"
              :to="'/game-start/kpop/' + content.fields.url"
            >
              <v-card-title
                class="card-title"
                v-text="content.fields.Qtitle"
              ></v-card-title>
              <v-card-text v-html="content.fields.discription"></v-card-text>
              <v-card-action>
                <v-chip
                  class="ma-2"
                  color="secondary"
                  v-for="chip in content.fields.chip"
                  :key="chip"
                  >{{ chip }}</v-chip
                >
              </v-card-action>
            </v-card>
          </v-col>
        </v-row>
      </div>
    </v-container>
  </v-app>
</template>
<script>
import { createClient } from "@/plugins/contentful";
import loding from "../myLoding.vue";

export default {
  components: {
    loding,
  },
  data() {
    return {
      contents: [],
      url: "",
      title: "",
      loding: true,
    };
  },
  async created() {
    // アーティスト取得
    const url = this.$route.path.split("/");
    this.url = url[2];
    // 画面幅取得
    this.width = window.innerWidth;
    // コンテントフル接続
    // カテゴリーからアーティスト取得
    await createClient()
      .getEntries({
        content_type: "kpop",
        "fields.categoly": this.url,
        order: "sys.createdAt",
      })
      .then((res) => {
        this.contents = res.items;
        this.title = this.contents[0].fields.title;
        this.loding = false;
      });
  },
};
</script>
<style scoped>
.card-title {
  background-color: #4fc3f7;
  margin-bottom: 20px;
}

.card-margin {
  margin-bottom: 20px;
}
</style>

<template>
  <v-card
    :height="height"
    color="#EA97A4"
    class="text-center"
    href="https://korean-letter.com/"
  >
    <div class="py-3"></div>
    <v-card-title class="font-bold grey-color">コリアンレター</v-card-title>
    <v-img :height="100" :src="require('@/assets/logo_k.png')"></v-img>
    <v-card-text class="grey-color"
      >あなたのお手紙を無料で<br />人間みのある文章に翻訳します。</v-card-text
    >
    <!-- <v-card-action v-if="this.width > 600">
      <v-btn href="https://korean-letter.com/"> こちらからチェック！ </v-btn>
    </v-card-action> -->
    <div class="py-2"></div>
  </v-card>
</template>

<script>
export default {
  name: "HelloWorld",
  data: () => ({
    width: 0,
    height: 0,
  }),
  created() {
    this.width = window.innerWidth;
    if (this.width < 600) {
      this.height = "auto";
    } else {
      this.height = 300;
    }
  },
};
</script>

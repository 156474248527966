import { createRouter, createWebHistory } from "vue-router";
import HomeView from "../views/HomeView.vue";
import SimpleKeyboard from "../views/SimpleKeyboard.vue";
import Keyboard from "../views/Typing/QuestionKeyboard.vue";
// Auth
import Mylogin from "../views/Auth/MyLogin.vue";
import MyLogOut from "../views/Auth/MyLogOut.vue";
import MyDelete from "../views/Auth/MyDelete.vue";
import MySignIn from "../views/Auth/MySignIn.vue";
import MyPage from "../views/Auth/MyPage.vue";
import PasswordRemind from "../views/Auth/PasswordRemind.vue";
import ResetPassword from "../views/Auth/ResetPassword.vue";
import MyVerify from "../views/Auth/MyVerify.vue";
import EmailReSend from "../views/Auth/EmailReSend.vue";
import questionRegister from "../views/Auth/questionRegister.vue";
import AccountInfo from "../views/Auth/AccountInfo.vue";
// Blog
import blog from "../views/Blog/myBlog.vue";
import blogAll from "../views/Blog/myBlogAll.vue";
// Chart
import chartView from "../views/Chart/chartView.vue";
import pcChart from "../views/Chart/pcChart.vue";
import spChart from "../views/Chart/spChart.vue";
// Info
import info from "../views/Info/myInfo.vue";
import infoAll from "../views/Info/myInfoAll.vue";
// Pages
import term from "../views/Pages/MyTerm.vue";
import privacy from "../views/Pages/MyPrivacy.vue";
import KoreanLetter from "../views/Pages/KoreanLetter.vue";
import QandA from "../views/Pages/QandA.vue";
// Ranking
import MyRanks from "../views/Ranking/MyRanking.vue";
import QuestionRanking from "../views/Ranking/QuestionRanking.vue";
// Stripe
import StripeCash from "../views/Stripe/StripeCash.vue";
import BuyThanks from "../views/Stripe/BuyThanks.vue";
import BuyButton from "../views/Stripe/BuyButton.vue";
// Typing
import GameSelect from "../views/Typing/GameSelect.vue";
import ArtistSelect from "../views/Typing/ArtistSelect.vue";
import TomisSelect from "../views/Typing/TomisSelect.vue";
// その他
import SelectVariety from "../views/SelectVariety.vue";
import contact from "../views/myContact.vue";
import loding from "../views/myLoding.vue";
import MyBackground from "../views/MyBackground.vue";
import KeyboardCopy from "../views/Copy/QuestionKeyboardCopy.vue";

const routes = [
  {
    path: "/",
    name: "home",
    component: HomeView,
  },
  {
    path: "/background",
    name: "MyBackground",
    component: MyBackground,
  },
  {
    path: "/game-start/:param/:id",
    name: "keyboard",
    component: Keyboard,
  },
  {
    path: "/board",
    name: "SimpleKeyboard",
    component: SimpleKeyboard,
  },
  {
    path: "/login",
    name: "login",
    component: Mylogin,
  },
  {
    path: "/logout",
    name: "logout",
    component: MyLogOut,
  },
  {
    path: "/delete",
    name: "delete",
    component: MyDelete,
  },
  {
    path: "/sign-in",
    name: "sign-in",
    component: MySignIn,
  },
  {
    path: "/mypage",
    name: "MyPage",
    component: MyPage,
  },
  {
    path: "/account-info",
    name: "AccountInfo",
    component: AccountInfo,
  },
  {
    path: "/password-remind",
    name: "password-remind",
    component: PasswordRemind,
  },
  {
    path: "/reset-password/:email/:token",
    name: "ResetPassword",
    component: ResetPassword,
  },
  {
    path: "/verification",
    name: "MyVerify",
    component: MyVerify,
  },
  {
    path: "/mail-send",
    name: "EmailReSend",
    component: EmailReSend,
  },
  {
    path: "/question-register",
    name: "questionRegister",
    component: questionRegister,
  },
  {
    path: "/game-select/:param",
    name: "GameSelect",
    component: GameSelect,
  },
  {
    path: "/select-variety",
    name: "SelectVariety",
    component: SelectVariety,
  },
  {
    path: "/korean-letter",
    name: "KoreanLetter",
    component: KoreanLetter,
  },
  {
    path: "/contact",
    name: "contact",
    component: contact,
  },
  {
    path: "/artist-select/:url",
    name: "ArtistSelect",
    component: ArtistSelect,
  },
  {
    path: "/game-select/TrilingualTomi/:url",
    name: "TomisSelect",
    component: TomisSelect,
  },
  {
    path: "/info/:url",
    name: "info",
    component: info,
  },
  {
    path: "/info",
    name: "infoAll",
    component: infoAll,
  },
  {
    path: "/blog/:url",
    name: "blog",
    component: blog,
  },
  {
    path: "/blog",
    name: "blogAll",
    component: blogAll,
  },
  {
    path: "/term",
    name: "term",
    component: term,
  },
  {
    path: "/privacy",
    name: "privacy",
    component: privacy,
  },
  {
    path: "/loding",
    name: "loding",
    component: loding,
  },
  {
    path: "/ranking",
    name: "MyRanks",
    component: MyRanks,
  },
  {
    path: "/ranking/:param",
    name: "QuestionRanking",
    component: QuestionRanking,
  },
  {
    path: "/QandA",
    name: "QandA",
    component: QandA,
  },
  {
    path: "/chart",
    name: "chartView",
    component: chartView,
  },
  {
    path: "/pc-chart",
    name: "pcChart",
    component: pcChart,
  },
  {
    path: "/sp-chart",
    name: "spChart",
    component: spChart,
  },
  {
    path: "/game-start-copy/:param/:url",
    name: "keyboardCopy",
    component: KeyboardCopy,
  },
  {
    path: "/subscription",
    name: "StripeCash",
    component: StripeCash,
  },
  {
    path: "/thanks",
    name: "BuyThanks",
    component: BuyThanks,
  },
  {
    path: "/buy-button",
    name: "BuyButton",
    component: BuyButton,
  },
];

// const router = createRouter({
//   routes,
// });

const router = createRouter({
  // ページ遷移時にページトップに移動
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return {
        top: 0,
      };
    }
  },
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;

<template>
  <v-app>
    <v-container>
      <v-btn @click="cancel">キャンセル</v-btn>
      <a :href="this.stripeUrl">支払い</a>
    </v-container>
  </v-app>
</template>
<script>
import { defineComponent } from "vue";

export default defineComponent({
  data: () => ({
    publishableKey: process.env.VUE_APP_STRIPE_PUBLISHABLE_KEY,
    apiUrl: process.env.VUE_APP_API_BASE_URL,
    stripeApiUrl: "https://api.stripe.com",
    loading: false,
    headers: {
      Authorization: "",
      "Content-type": "application/json",
      Accept: "application/json,",
      data: {},
    },
    stripe: null,
    email: "",
    userID: "",
    token: "",
    stripeUrl: "",
    stripePromise: null,
    status: false,
    stripeId: null,
  }),
  created() {
    // this.stripe = loadStripe(this.publishableKey);
    this.getUserID();
    this.getStatus(); // 現在の課金状況取得
  },
  mounted() {},
  computed: {},
  methods: {
    // 課金状況取得
    async getStatus() {
      const url = this.apiUrl + "subscription/getStatus";
      let res = await this.axios.get(url, { headers: this.headers });
      console.log(res.data);
      this.status = res.data.status;
      if (!this.status) {
        this.stripeId = res.data.stripe_id;
      }
    },
    // キャンセル
    async cancel() {
      const url = this.apiUrl + "subscription/cancel";
      let res = await this.axios.post(url, {}, { headers: this.headers });
      console.log(res);
    },
    // ユーザID取得
    getUserID() {
      if (localStorage.getItem("loginData") === null) {
        alert("ログインしてください");
        this.$router.push({ path: "/login" });
      } else {
        const obj = localStorage.getItem("loginData");
        const loginData = JSON.parse(obj);
        this.userID = loginData.userID;
        this.token = loginData.token;
        this.headers.Authorization = "Bearer " + this.token;
        this.getUser(); // ユーザ情報取得
      }
    },
    // ユーザ情報取得
    getUser() {
      this.axios
        .get(this.apiUrl + "user", {
          headers: this.headers,
        })
        .then((res) => {
          console.log(res.data);
          this.email = res.data.email;
          this.userID = res.data.id;
          this.createStripeURL();
          this.getStripeInfo();
        })
        .catch((e) => {
          console.log(e);
        });
    },
    // stripeリンクのパラメータ作成
    createStripeURL() {
      const url =
        "https://buy.stripe.com/test_28odRpdtGbFH9d6288?prefilled_email=";
      this.stripeUrl = url + this.email + "&client_reference_id=" + this.userID;
    },
  },
});
</script>
<style></style>

import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
// import vuetify from "./plugins/vuetify";
import "vuetify/styles";
import { createVuetify } from "vuetify";
// import { loadFonts } from "./plugins/webfontloader";
import "@mdi/font/css/materialdesignicons.css";
import Hangul from "hangul-js";
import VueSocialSharing from "vue-social-sharing";
import VueGtag from "vue-gtag";
import typingKey from "./plugins/typingKey";
import "./registerServiceWorker";
import { createHead } from "@vueuse/head";
import axios from "axios"; //追記
import VueAxios from "vue-axios"; //追記
// import { loadStripe } from "@stripe/stripe-js";
// import { createStripe, StripePlugin } from "@vue-stripe/vue-stripe";

// loadFonts();
const head = createHead();

axios.defaults.withCredentials = true;
axios.defaults.xsrfHeaderName = "X-CSRF-Token";

const vuetify = createVuetify();

createApp(App)
  .use(router)
  .use(store)
  .use(vuetify)
  .use(Hangul)
  .use(typingKey)
  .use(VueSocialSharing)
  .use(head)
  // .use(loadStripe)
  // .use(StripePlugin, {
  //   stripe: createStripe(process.env.VUE_APP_STRIPE_PUBLISHABLE_KEY),
  // })
  .use(VueAxios, axios)
  // .use(AxiosPlugin)
  .use(
    VueGtag,
    {
      config: { id: "G-C8GTPXEJFV" },
    },
    router
  )
  .mount("#app");

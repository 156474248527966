<template>
  <v-app class="bg-img">
    <div class="py-3"></div>
    <v-container>
      <div v-if="this.loding == true">
        <loding />
      </div>
      <div v-else>
        <v-row justify="center">
          <v-col md="12" cols="12">
            <h2 class="text-center">トリリンガルのトミ韓国講座：{{ title }}</h2>
          </v-col>
          <v-col md="6" cols="12">
            <v-card
              v-for="(content, i) in contents"
              :key="i"
              class="text-left card-margin"
              :to="'/game-start/tomi/' + content.id"
            >
              <v-card-title
                class="card-title"
                v-text="content.title"
              ></v-card-title>
              <v-card-text v-html="content.description"></v-card-text>
              <v-card-text class="font-count text-right"
                >表示回数 {{ content.count }}</v-card-text
              >
            </v-card>
          </v-col>
          <v-col md="12" cols="12" v-if="error !== ''" class="text-center">
            <p>{{ error }}</p>
          </v-col>
        </v-row>
      </div>
    </v-container>
  </v-app>
</template>
<script>
import loding from "../myLoding.vue";

export default {
  components: {
    loding,
  },
  data() {
    return {
      contents: [],
      url: "",
      title: "",
      loding: true,
      error: "",
      apiUrl: process.env.VUE_APP_API_BASE_URL,
    };
  },
  async created() {
    // レベル取得
    const url = this.$route.path.split("/");
    this.url = url[3];
    console.log(this.url);
    // 画面幅取得
    this.width = window.innerWidth;
    // カテゴリーからレベル別問題取得
    this.getTitle();
    this.getQuestion();
  },
  methods: {
    getTitle() {
      if (this.url == "beginner") {
        this.title = "初級";
      } else if (this.url == "intermediate") {
        this.title = "中級";
      }
    },
    getQuestion() {
      this.axios
        .get(this.apiUrl + "question/tomisShow/" + this.url)
        .then((res) => {
          if (res.data.status_code === 201) {
            this.error = "問題を準備中です...";
          } else {
            this.contents = res.data;
          }
          this.loding = false;
        });
    },
  },
};
</script>
<style scoped>
.card-title {
  background-color: #4fc3f7;
  margin-bottom: 20px;
}

.card-margin {
  margin-bottom: 20px;
}

.font-count {
  font-size: small;
  margin-top: 0;
  padding-top: 0;
  font-weight: bold;
  color: yellowgreen;
}
</style>

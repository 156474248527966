<template>
  <v-app class="bg-img">
    <div class="py-3"></div>
    <v-container>
      <div class="py-3"></div>
      <v-card width="350px" class="mx-auto mt-4" cols="12" sm="4">
        <v-card-title class="card-title">
          <h3 class="display-2">ユーザ登録</h3>
        </v-card-title>
        <v-card-text>
          <v-form>
            <v-text-field
              prepend-icon="mdi-account-circle"
              label="ニックネーム"
              v-model="name"
              required
            />
            <v-text-field
              prepend-icon="mdi-account-circle"
              label="メールアドレス"
              v-model="email"
              required
            />
            <v-text-field
              v-bind:type="showPassword ? 'text' : 'password'"
              prepend-icon="mdi-lock"
              v-bind:append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
              label="パスワード"
              @click:append="showPassword = !showPassword"
              v-model="password"
              required
            />
            <v-text-field
              v-bind:type="showPassword2 ? 'text' : 'password'"
              prepend-icon="mdi-lock"
              v-bind:append-icon="showPassword2 ? 'mdi-eye' : 'mdi-eye-off'"
              label="パスワード再入力"
              @click:append="showPassword2 = !showPassword2"
              v-model="password_confirmation"
              required
            />
            <v-card-actions>
              <v-btn color="blue" @click="createNewUser">これで登録する</v-btn>
            </v-card-actions>
            <p>
              <router-link to="/login">
                すでにアカウントを持っている方はこちら >
              </router-link>
            </p>
          </v-form>
        </v-card-text>
      </v-card>
      <p v-if="alert !== ''" v-html="alert" class="error-text text-center"></p>
    </v-container>
  </v-app>
</template>
<script>
import axios from "axios";

export default {
  data: () => ({
    showPassword: false,
    showPassword2: false,
    name: "",
    email: "",
    password: "",
    password_confirmation: "",
    alert: "",
    apiUrl: process.env.VUE_APP_API_BASE_URL,
  }),
  created: function () {
    // ログインしていたらホームに遷移
    if (localStorage.getItem("loginData") !== null) {
      this.$router.push({ path: "/" });
    }
  },
  methods: {
    createNewUser: async function () {
      if (this.name === "" || this.email === "" || this.password === "") {
        alert("入力項目はすべて必須項目です");
        return;
      }
      if (this.password.length < 8 || this.password_confirmation.length < 8) {
        alert("パスワードは8文字以上でご入力ください");
        this.password = "";
        this.password_confirmation = "";
        return;
      }
      if (this.password !== this.password_confirmation) {
        alert("パスワードが一致していません.");
        this.password = "";
        this.password_confirmation = "";
        return;
      }
      axios.withCredentials = true;
      await axios
        .post(this.apiUrl + "register", {
          name: this.name,
          email: this.email,
          password: this.password,
          password_confirmation: this.password_confirmation,
        })
        .then((res) => {
          console.log(res);
          if (res.status === 201) {
            this.alert =
              "メール送信が完了しました。<br>10分以上待っても届かない場合は迷惑フォルダもご確認ください。";
            this.name = "";
            this.email = "";
            this.password = "";
            this.password_confirmation = "";
          } else {
            this.alert =
              "メール送信に失敗しました。メールの再送をお試しください";
            this.name = "";
            this.email = "";
            this.password = "";
            this.password_confirmation = "";
          }
        })
        .catch((error) => {
          console.log(error);
          if (
            error.response.data.message === "The email has already been taken."
          ) {
            alert("メールアドレスはすでに登録されています");
            this.name = "";
            this.email = "";
            this.password = "";
            this.password_confirmation = "";
            return;
          }
          if (
            error.response.data.massage === "emailの値は既に存在しています。"
          ) {
            alert("emailの値は既に存在しています。");
            this.name = "";
            this.email = "";
            this.password = "";
            this.password_confirmation = "";
            return;
          }
          alert("会員登録に失敗しました。再度ご登録をお願いします");
          this.name = "";
          this.email = "";
          this.password = "";
          this.password_confirmation = "";
        });
    },
  },
};
</script>
<style lang="scss" scoped>
#app {
  background-color: #f7f7f7;

  .container {
    width: 100%;

    .row {
      width: 100%;

      .card {
        width: 100%;
        height: 100%;

        .profile-img {
          width: 100%;
        }

        .form-signin {
          width: 100%;
          padding: 0px 0 0px 0;
          margin: 0 auto;

          .checkbox {
            margin-bottom: 10px;
          }

          .btn-primary {
            margin: 20px 0 !important;
            width: 80%;
          }
        }
      }
    }
  }
}

.form-signin .form-signin .checkbox {
  font-weight: normal;
}
.form-signin .form-control {
  position: relative;
  font-size: 16px;
  height: auto;
  padding: 10px;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
.form-signin .form-control:focus {
  z-index: 2;
}
.form-signin input[type="text"] {
  margin-bottom: -1px;
  border-radius: 0;
  border-left: 0;
  border-right: 0;
}
.form-signin input[type="password"] {
  border-radius: 0;
  border-left: 0;
  border-right: 0;
  margin-bottom: -1px;
}
.login-title {
  color: #000;
  font-size: 22px;
  font-weight: 600;
  display: block;
}
.new-account {
  display: block;
  margin: 10px 0;
}

.error-text {
  margin-top: 20px;
  color: darkblue;
  font-weight: bold;
}

#error {
  color: red;
  display: none;
}
</style>

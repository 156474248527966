const hangulChange = (hangul, shiftToggle) => {
  // 子音
  if (hangul == "KeyQ" && shiftToggle === false) {
    let text = "";
    text = "ㅂ";
    return text;
  } else if (hangul == "KeyW" && shiftToggle === false) {
    let text = "";
    text = "ㅈ";
    return text;
  } else if (hangul == "KeyE" && shiftToggle === false) {
    let text = "";
    text = "ㄷ";
    return text;
  } else if (hangul == "KeyR" && shiftToggle === false) {
    let text = "";
    text = "ㄱ";
    return text;
  } else if (hangul == "KeyT" && shiftToggle === false) {
    let text = "";
    text = "ㅅ";
    return text;
  } else if (hangul == "KeyY") {
    let text = "";
    text = "ㅛ";
    return text;
  } else if (hangul == "KeyU") {
    let text = "";
    text = "ㅕ";
    return text;
  } else if (hangul == "KeyI") {
    let text = "";
    text = "ㅑ";
    return text;
  } else if (hangul == "KeyO" && shiftToggle === false) {
    let text = "";
    text = "ㅐ";
    return text;
  } else if (hangul == "KeyP" && shiftToggle === false) {
    let text = "";
    text = "ㅔ";
    return text;
  } else if (hangul == "KeyA") {
    let text = "";
    text = "ㅁ";
    return text;
  } else if (hangul == "KeyS") {
    let text = "";
    text = "ㄴ";
    return text;
  } else if (hangul == "KeyD") {
    let text = "";
    text = "ㅇ";
    return text;
  } else if (hangul == "KeyF") {
    let text = "";
    text = "ㄹ";
    return text;
    //   母音
  } else if (hangul == "KeyG") {
    let text = "";
    text = "ㅎ";
    return text;
  } else if (hangul == "KeyH") {
    let text = "";
    text = "ㅗ";
    return text;
  } else if (hangul == "KeyJ") {
    let text = "";
    text = "ㅓ";
    return text;
  } else if (hangul == "KeyK") {
    let text = "";
    text = "ㅏ";
    return text;
  } else if (hangul == "KeyL") {
    let text = "";
    text = "ㅣ";
    return text;
  } else if (hangul == "KeyZ") {
    let text = "";
    text = "ㅋ";
    return text;
  } else if (hangul == "KeyX") {
    let text = "";
    text = "ㅌ";
    return text;
  } else if (hangul == "KeyC") {
    let text = "";
    text = "ㅊ";
    return text;
  } else if (hangul == "KeyV") {
    let text = "";
    text = "ㅍ";
    return text;
  } else if (hangul == "KeyB") {
    let text = "";
    text = "ㅠ";
    return text;
  } else if (hangul == "KeyN") {
    let text = "";
    text = "ㅜ";
    return text;
  } else if (hangul == "KeyM") {
    let text = "";
    text = "ㅡ";
    return text;
  } else if (hangul == "Space") {
    let text = "";
    text = "Space";
    return text;
  } else if (hangul == "ShiftLeft") {
    let text = "";
    text = "Shift";
    return text;
  } else if (hangul == "ShiftRight") {
    let text = "";
    text = "Shift";
    return text;
  } else if (hangul === "Backspace") {
    let text = "";
    text = "Backspace";
    return text;
  } else if (hangul === "Delete") {
    let text = "";
    text = "Backspace";
    return text;
  } else if (hangul === "Enter") {
    let text = "";
    text = "Enter";
    return text;
  } else if (hangul === "KeyQ" && shiftToggle === true) {
    let text = "";
    text = "ㅃ";
    return text;
  } else if (hangul === "KeyW" && shiftToggle === true) {
    let text = "";
    text = "ㅉ";
    return text;
  } else if (hangul === "KeyE" && shiftToggle === true) {
    let text = "";
    text = "ㄸ";
    return text;
  } else if (hangul === "KeyR" && shiftToggle === true) {
    let text = "";
    text = "ㄲ";
    return text;
  } else if (hangul === "KeyT" && shiftToggle === true) {
    let text = "";
    text = "ㅆ";
    return text;
  } else if (hangul === "KeyO" && shiftToggle === true) {
    let text = "";
    text = "ㅒ";
    return text;
  } else if (hangul === "KeyP" && shiftToggle === true) {
    let text = "";
    text = "ㅖ";
    return text;
  } else {
    let text = "";
    text = "Shift";
    return text;
  }
  // else if (hangul == "Key") {
  //   let text = "";
  //   text = "{shift} ᄊ";
  //   return text;
  // } else if (hangul == "Key") {
  //   let text = "";
  //   text = "{shift} ᄈ";
  //   return text;
  // } else if (hangul == "Key") {
  //   let text = "";
  //   text = "{shift} ᄁ";
  //   return text;
  // } else if (hangul == "Key") {
  //   let text = "";
  //   text = "{shift} ᄍ";
  //   return text;
  // } else if (hangul == "Key") {
  //   let text = "";
  //   text = "{shift} ᄄ";
  //   return text;
  // } else if (hangul == "Key") {
  //   let text = "";
  //   text = "{shift} ᅤ";
  //   return text;
  // } else if (hangul == "Key") {
  //   let text = "";
  //   text = "{shift} ᅨ";
  //   return text;
  // } else if (hangul == " ") {
  //   let text = "";
  //   text = "{space}";
  //   return text;
  // } else {
  //   console.log("ハングルモードに変更しましょう");
  // }
};

export default hangulChange;

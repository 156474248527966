<template>
  <v-row justify="center">
    <v-col md="6" xs="12">
      <span style="font-family: arial, helvetica, sans-serif"
        ><div class="reviewBox_blue">
          <p class="reviewBox_blue_title1">初心者おすすめ書籍</p>
          <div class="reviewBox_blue_content">
            <div class="reviewBox_blue_top">
              <div class="reviewBox_blue_left">
                <p class="reviewBox_blue_title2 grey-color">
                  新装版 できる韓国語 初級I
                </p>
                <p class="grey-color">韓国語初心者に最適な1冊!</p>
                <p class="grey-color">
                  この一冊で基礎的な韓国語が話せる様になれる!
                </p>
                <p class="grey-color">韓国語学習の第一歩におすすめ!!!</p>
              </div>
              <div class="reviewBox_blue_right">
                <img
                  :src="require('@/assets/amazon5.jpg')"
                  alt=""
                  width="400"
                  height="270"
                  class="alignnone wp-image-1179 size-full"
                />
              </div>
            </div>
            <div class="reviewBox_blue_center">
              <v-row class="text-center">
                <v-col>
                  <v-progress-circular
                    :model-value="90"
                    color="#54b3fc"
                    width="6"
                    size="57"
                    >9</v-progress-circular
                  >
                  <p class="text-size">初心者向け</p>
                </v-col>
                <v-col>
                  <v-progress-circular
                    :model-value="80"
                    color="#54b3fc"
                    width="6"
                    size="57"
                    >8</v-progress-circular
                  >
                  <p class="text-size">コスパ</p>
                </v-col>
                <v-col>
                  <v-progress-circular
                    :model-value="90"
                    color="#54b3fc"
                    width="6"
                    size="57"
                    >9</v-progress-circular
                  >
                  <p class="text-size">わかりやすさ</p>
                </v-col>
                <v-col>
                  <v-progress-circular
                    :model-value="60"
                    color="#54b3fc"
                    width="6"
                    size="57"
                    >6</v-progress-circular
                  >
                  <p class="text-size">新しさ</p>
                </v-col>
              </v-row>
            </div>
            <v-col md="12" cols="12" class="text-center">
              <v-btn color="#ffff99" href="https://amzn.to/3koLKnO" width="200"
                >Amazon</v-btn
              >
            </v-col>
          </div>
        </div>
      </span>
    </v-col>
    <!-- 商品2 -->
    <v-col md="6" xs="12">
      <span style="font-family: arial, helvetica, sans-serif"
        ><div class="reviewBox_blue">
          <p class="reviewBox_blue_title1">中級者おすすめ書籍</p>
          <div class="reviewBox_blue_content">
            <div class="reviewBox_blue_top">
              <div class="reviewBox_blue_left">
                <p class="reviewBox_blue_title2 grey-color">
                  CD付 改訂版 <br />できる韓国語 中級I
                </p>
                <p>豊富な文法！</p>
                <p>必須文型100種まとめが掲載！</p>
                <p>自然な韓国語を身に付けたい方におすすめ！</p>
              </div>
              <div class="reviewBox_blue_right">
                <img
                  :src="require('@/assets/amazon4.jpg')"
                  alt=""
                  width="400"
                  height="270"
                  class="alignnone wp-image-1179 size-full"
                />
              </div>
            </div>
            <div class="reviewBox_blue_center">
              <v-row class="text-center">
                <v-col>
                  <v-progress-circular
                    :model-value="90"
                    color="#54b3fc"
                    width="6"
                    size="57"
                    >9</v-progress-circular
                  >
                  <p class="text-size">内容</p>
                </v-col>
                <v-col>
                  <v-progress-circular
                    :model-value="80"
                    color="#54b3fc"
                    width="6"
                    size="57"
                    >8</v-progress-circular
                  >
                  <p class="text-size">難易度</p>
                </v-col>
                <v-col>
                  <v-progress-circular
                    :model-value="60"
                    color="#54b3fc"
                    width="6"
                    size="57"
                    >6</v-progress-circular
                  >
                  <p class="text-size">分かりやすさ</p>
                </v-col>
                <v-col>
                  <v-progress-circular
                    :model-value="60"
                    color="#54b3fc"
                    width="6"
                    size="57"
                    >6</v-progress-circular
                  >
                  <p class="text-size">コスパ</p>
                </v-col>
              </v-row>
            </div>
            <v-col md="12" cols="12" class="text-center">
              <v-btn color="#ffff99" href="https://amzn.to/3KyXGy5" width="200"
                >Amazon</v-btn
              >
            </v-col>
          </div>
        </div>
      </span>
    </v-col>
  </v-row>
</template>
<script>
export default {
  // el:'#app',
  data: () => ({
    width: 0,
    cardHeight: 0,
    order: "",
    circularSize: 0,
    fontSize: "",
  }),
  created() {
    // 画面幅取得
    this.width = window.innerWidth;
    if (this.width < 500) {
      this.cardHeight = 700;
      this.order = "last";
      this.circularSize = 40;
      this.fontSize = "11px";
    } else {
      this.cardHeight = 550;
      this.order = "first";
      this.circularSize = 55;
      this.fontSize = "15px";
    }
  },
};
</script>
<style scoped>
.padding {
  padding: 0px 20px;
}
.margin {
  margin: 20px 0px;
}

.text-size {
  font-size: v-bind(fontSize);
  margin-top: 7px;
  font-weight: bold;
  color: #4d4d4d;
}
/* .circular-margin {
  margin: 0px 10px;
} */

.reviewBox_blue {
  display: block;
  max-width: 500px;
  background: #eff9ff;
  border: 1px solid #54b3fc;
  margin: 20px auto;
  padding: 0;
  border-radius: 10px;
}

.reviewBox_blue .reviewBox_blue_content {
  padding: 30px;
}

.reviewBox_blue .reviewBox_blue_title1 {
  margin: 0;
  padding: 12px;
  background: #54b3fc;
  text-align: center;
  font-size: 15px !important;
  font-weight: bold;
  border-radius: 10px 10px 0 0;
  line-height: 1.3em;
  border: none;
  color: #fff !important;
}

.reviewBox_blue .reviewBox_blue_title1:before {
  font-family: "Font Awesome 5 Free";
  /* content: "\f005"; */
  font-weight: 600;
  padding-right: 5px;
  font-size: 0.9em !important;
}

.reviewBox_blue .reviewBox_blue_title2 {
  margin: 0;
  padding: 0;
  line-height: 1.3em;
  font-size: 16px;
  margin-bottom: 10px;
  font-weight: bold;
  border: none;
}

.reviewBox_blue .reviewBox_blue_title2:before {
  content: none !important;
}

.reviewBox_blue .reviewBox_blue_top {
  margin: auto;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
}

.reviewBox_blue .reviewBox_blue_top p {
  margin: 0;
  padding: 0;
  line-height: 1.3em;
  padding-bottom: 12px;
  font-size: 15px !important;
}

.reviewBox_blue .reviewBox_blue_top p:before {
  font-family: "Font Awesome 5 Free";
  /* content: "\f00c"; */
  font-weight: 600;
  padding-right: 5px;
  font-size: 0.8em;
}

.reviewBox_blue .reviewBox_blue_top .reviewBox_blue_left,
.reviewBox_blue .reviewBox_blue_top .reviewBox_blue_right {
  width: 50%;
}

.reviewBox_blue .reviewBox_blue_top .reviewBox_blue_right img {
  display: block;
  margin: auto;
  width: 160px;
}

.reviewBox_blue .reviewBox_blue_center {
  margin-top: 10px;
}

.reviewBox_blue .reviewLink {
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.reviewBox_blue .reviewLink a {
  font-size: 15px !important;
  padding: 0.6em 2em;
  margin: 5px;
  width: 40%;
  text-align: center;
  margin-top: 30px;
  text-decoration: none;
  background: #54b3fc;
  color: #fff !important;
  border: 2px solid #54b3fc;
}

.reviewBox_blue .reviewLink a:nth-child(2) {
}

.reviewBox_blue .reviewLink a:before {
  font-family: "Font Awesome 5 Free";
  /* content: "\f138"; */
  font-weight: 600;
  padding-right: 5px;
}

.reviewBox_blue .reviewBox_blue_center {
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  padding: 20px 0;
}

.reviewBox_blue .reviewBox_blue_center .progress-circle {
  width: 25%;
  margin: auto;
}

.reviewBox_blue .progress-circle {
  font-size: 12px !important;
  margin: 20px;
  position: relative;
  /* so that children can be absolutely positioned */
  padding: 0;
  width: 5em !important;
  height: 5em !important;
  background-color: #c8e8fe;
  border-radius: 50%;
  line-height: 5em;
}

.reviewBox_blue .reviewBox_blue_score {
  font-size: 13px !important;
  text-align: center;
  line-height: 1em;
  z-index: 4;
  padding-top: 5.5em;
  display: block;
  font-weight: 600;
}

.reviewBox_blue .reviewBox_blue_score p {
  margin: 0;
  font-size: 12px !important;
  width: 120% !important;
  margin-left: -10% !important;
}

.reviewBox_blue .progress-circle:after {
  border: none;
  position: absolute;
  top: 0.5em;
  left: 0.5em;
  text-align: center;
  display: block;
  border-radius: 50%;
  width: 4em;
  height: 4em;
  background-color: #eff9ff;
  content: " ";
}

.reviewBox_blue .progress-circle .progress-text {
  position: absolute;
  line-height: 2.6em;
  width: 2.6em;
  text-align: center;
  display: block;
  color: #444 !important;
  font-size: 23px !important;
  z-index: 2;
  font-family: -apple-system, BlinkMacSystemFont, Roboto, "Helvetica Neue",
    HelveticaNeue, "游ゴシック体", YuGothic, "游ゴシック Medium",
    "Yu Gothic Medium", "游ゴシック", "Yu Gothic", "Sogoe UI", Verdana,
    "メイリオ", Meiryo, sans-serif;
}

.reviewBox_blue .left-half-clipper {
  border-radius: 50%;
  width: 5em;
  height: 5em;
  position: absolute;
  /* needed for clipping */
  clip: rect(0, 5em, 5em, 2.5em);
  /* clips the whole left half*/
}

.reviewBox_blue .progress-circle.over50 .left-half-clipper {
  clip: rect(auto, auto, auto, auto);
}

.reviewBox_blue .value-bar {
  position: absolute;
  /*needed for clipping*/
  clip: rect(0, 2.5em, 5em, 0);
  width: 5em !important;
  height: 5em !important;
  border-radius: 50%;
  border: 0.45em solid #54b3fc;
  box-sizing: border-box;
}

.reviewBox_blue .progress-circle.over50 .first50-bar {
  position: absolute;
  /*needed for clipping*/
  clip: rect(0, 5em, 5em, 2.5em);
  background-color: #b3e6fc;
  border-radius: 50%;
  width: 5em;
  height: 5em;
}

.reviewBox_blue .progress-circle:not(.over50) .first50-bar {
  display: none;
}

.reviewBox_blue .progress-circle.p0 .value-bar {
  display: none;
}

.reviewBox_blue .progress-circle.p1 .value-bar {
  transform: rotate(4deg);
}

.reviewBox_blue .progress-circle.p10 .value-bar {
  transform: rotate(36deg);
}

.reviewBox_blue .progress-circle.p20 .value-bar {
  transform: rotate(72deg);
}

.reviewBo_blue .progress-circle.p30 .value-bar {
  transform: rotate(108deg);
}

.reviewBox_blue .progress-circle.p40 .value-bar {
  transform: rotate(144deg);
}

.reviewBox_blue .progress-circle.p50 .value-bar {
  transform: rotate(180deg);
}

.reviewBox_blue .progress-circle.p60 .value-bar {
  transform: rotate(216deg);
}

.reviewBox_blue .progress-circle.p70 .value-bar {
  transform: rotate(252deg);
}

.reviewBox_blue .progress-circle.p80 .value-bar {
  transform: rotate(288deg);
}

.reviewBox_blue .progress-circle.p90 .value-bar {
  transform: rotate(324deg);
}

.reviewBox_blue .progress-circle.p100 .value-bar {
  transform: rotate(360deg);
}

@media screen and (max-width: 480px) {
  .reviewBox_blue .reviewBox_blue_content {
    padding: 15px;
  }

  .reviewBox_blue .reviewBox_blue_top .reviewBox_blue_right img {
    width: 220px;
  }

  .reviewBox_blue .reviewBox_blue_top p {
    font-size: 13px !important;
  }

  .reviewBox_blue .reviewBox_blue_top p:first-child {
    font-size: 15px !important;
  }

  .reviewBox_blue .reviewBox_blue_center {
    padding: 0;
    margin-top: 20px;
    margin-bottom: 20px;
  }

  .reviewBox_blue .reviewLink a {
    padding: 0.5em;
  }

  .reviewBox_blue .reviewBox_blue_top {
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
  }

  .reviewBox_blue .reviewBox_blue_top .reviewBox_blue_left {
    -webkit-box-ordinal-group: 2;
    -ms-flex-order: 2;
    -webkit-order: 2;
    order: 2;
    width: 90%;
    margin: auto;
    margin-top: 20px;
  }

  .reviewBox_blue .reviewBox_blue_top .reviewBox_blue_right {
    -webkit-box-ordinal-group: 1;
    -ms-flex-order: 1;
    -webkit-order: 1;
    order: 1;
    width: 100%;
  }

  .reviewBox_blue .reviewLink a {
    font-size: 13px !important;
  }

  .reviewBox_blue .progress-circle .progress-text {
    line-height: 2.2em;
    width: 2.2em;
    font-size: 23px !important;
  }

  .reviewBox_blue .progress-circle {
    font-size: 10px !important;
  }

  .reviewBox_blue .reviewBox_blue_score p {
    font-size: 10px !important;
  }
}

.nomadBox_deco13 {
  border: 1px solid #272727;
  background: #f6f6f8;
  margin: 15px 0;
}
.nomadBox_deco13 h7 {
  margin: 0;
  padding: 10px;
  font-size: 18px;
  color: #fff;
  background: #272727;
  font-weight: bold;
  border: none;
}
.nomadBox_deco13 h7:before {
  font-family: "Font Awesome 5 Free";
  content: "\f304";
  font-size: 15px;
  font-weight: 600;
  padding-right: 5px;
  padding-left: 5px;
}
.nomadBox_deco13 p {
  padding: 20px;
  line-height: 1.5em;
  margin: 0;
}
</style>

<template>
  <v-app class="bg-img">
    <div class="py-3"></div>
    <v-container>
      <v-card width="350px" class="mx-auto mt-4" cols="12" sm="4">
        <v-card-title class="card-title">
          <h3 class="display-2">ログアウト</h3>
        </v-card-title>
        <v-card-text>
          <v-form>
            <p>ログアウトしますか？</p>
            <br />
            <v-card-actions>
              <v-btn color="blue" @click="logout">ログアウト</v-btn>
            </v-card-actions>
          </v-form>
        </v-card-text>
      </v-card>
    </v-container>
  </v-app>
</template>
<script>
export default {
  data: () => ({
    url: "",
  }),
  created: function () {},
  methods: {
    logout: function () {
      alert("ログアウトしました");
      localStorage.clear();
      location.href = "/";
    },
  },
};
</script>
<style lang="scss" scoped></style>

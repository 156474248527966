<template>
  <v-app class="bg-img">
    <div class="py-3 bg-img"></div>
    <v-container>
      <v-row>
        <v-col cols="12">
          <h2 class="text-center grey">よくある質問</h2>
        </v-col>
      </v-row>
      <h3 class="qa-title">利用について</h3>
      <v-expansion-panels>
        <v-expansion-panel
          v-for="(list, i) in useQA"
          :key="i"
          :title="list.title"
          color="#FFFF99"
        >
          <v-expansion-panel-text
            class="qa-description text-left"
            v-html="list.text"
          ></v-expansion-panel-text>
        </v-expansion-panel>
      </v-expansion-panels>
      <h3 class="qa-title">使い方について</h3>
      <v-expansion-panels>
        <v-expansion-panel
          v-for="(list, i) in howQA"
          :key="i"
          :title="list.title"
          color="#FFFF99"
        >
          <v-expansion-panel-text
            class="qa-description"
            v-html="list.text"
          ></v-expansion-panel-text>
        </v-expansion-panel>
      </v-expansion-panels>

      <h3 class="qa-title">動作環境について</h3>
      <v-expansion-panels>
        <v-expansion-panel
          v-for="(list, i) in osQA"
          :key="i"
          :title="list.title"
          color="#FFFF99"
        >
          <v-expansion-panel-text
            class="qa-description"
            v-html="list.text"
          ></v-expansion-panel-text>
          <v-expansion-panel-text
            class="qa-link"
            v-html="list.url"
          ></v-expansion-panel-text>
        </v-expansion-panel>
      </v-expansion-panels>

      <h3 class="qa-title">困った時は</h3>
      <v-expansion-panels>
        <v-expansion-panel
          v-for="(list, i) in helpQA"
          :key="i"
          :title="list.title"
          color="#FFFF99"
        >
          <v-expansion-panel-text
            class="qa-description"
            v-html="list.text"
          ></v-expansion-panel-text>
          <v-expansion-panel-text
            class="qa-link"
            v-html="list.url"
          ></v-expansion-panel-text>
        </v-expansion-panel>
      </v-expansion-panels>

      <h3 class="qa-title">その他</h3>
      <v-expansion-panels>
        <v-expansion-panel
          v-for="(list, i) in elseQA"
          :key="i"
          :title="list.title"
          color="#FFFF99"
        >
          <v-expansion-panel-text
            class="qa-description"
            v-html="list.text"
          ></v-expansion-panel-text>
          <v-expansion-panel-text
            class="qa-link"
            v-html="list.url"
          ></v-expansion-panel-text>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-container>
  </v-app>
</template>

<script>
import { defineComponent, computed, reactive } from "vue";
import { useHead } from "@vueuse/head";

export default defineComponent({
  components: {},
  data() {
    return {
      // 各種サイズ
      width: 0,
      useQA: [
        {
          title: "無料で利用できますか？",
          text: "コリアンタイピングは会員登録を行って無料で韓国語のタイピング練習が行えます。",
        },
        {
          title: "有料のサービスはありますか？",
          text: "現時点では有料サービスはありません。<br>全て無料でお楽しみいただけます。",
        },
        {
          title: "学校・企業で利用できますか？",
          text: "利用できます。<br>研修や入社試験にてご利用くださいませ。",
        },
      ],
      howQA: [
        {
          title: "まず何から始めたら良いですか？",
          text: "まずは会員登録をすることをおすすめします。<br>会員登録を行うと、ランキング機能で他人と競いながらタイピングスキルを高められるようになります。",
        },
        {
          title: "初心者はどの練習から始めたら良いですか？",
          text: "最初に腕試しレベルチェックを行いましょう。<br>現在のタイピングレベルを確認できます。",
        },
        {
          title: "会員登録すればどのような機能が使えますか？",
          text: "ランキング機能をお楽しみいただけます。<br>全国のユーザと共に競い合いレベルを上げていきましょう。",
        },
        {
          title: "スマホでもタイピング練習できますか？",
          text: "練習できます。<br>ただし、パソコンと異なり「苦手キー一覧」などが確認できない仕様上の制約がございます。",
        },
      ],
      osQA: [
        {
          title: "利用可能な端末について",
          text: "コリアンタイピングはPCとスマホで遊べるWEBアプリです。<br>※スマホアプリではありません",
        },
        {
          title: "必要動作環境",
          text: "対応ブラウザ  Edge / Firefox / Safari / Chrome <br>※ご利用いただくにはハングルキーボードの設定が必要です<br>※JavaScriptがONになっている必要があります",
          url: "<a href='https://trilingual.jp/jako/20190630-2429/'>PCでの設定方法</a>、<a href='https://trilingual.jp/jako/20190701-2151/'>スマホの設定方法</a>",
        },
      ],
      helpQA: [
        {
          title: "ログインできません",
          text: "以下の原因が考えられます<br>・メールアドレスとパスワードが一致していない<br>・まだ会員登録を行なっていない<br>※パスワードを忘れた場合はパスワード再設定をお願いします。",
          url: "<a href='/sign-in'>会員登録</a>、<a href='password-remind'>パスワード再設定</a>",
        },
        {
          title: "ハングル文字が入力できません",
          text: "※ご利用いただくにはハングルキーボードの設定が必要です<br>※JavaScriptがONになっている必要があります",
          url: "<a href='https://trilingual.jp/jako/20190630-2429/'>PCでの設定方法</a>、<a href='https://trilingual.jp/jako/20190701-2151/'>スマホの設定方法</a>",
        },
        {
          title: "退会したいです",
          text: "以下から退会をお願いします。<br>※退会するには一度ログインする必要があります<br>※退会するとランキングに参加できなくなりますのでご注意ください",
          url: "<a href='/delete'>退会</a>",
        },
      ],
      elseQA: [
        {
          title: "WPMってなに？",
          text: "「WPM」 words per minute<br>一分あたりの入力文字（英数字キー）数の意味です。",
        },
        {
          title: "スコアってなに？",
          text: "タイピングで大切なのは、速さよりも正確さです。<br>正確な技能はパソコンの利用目的に関係なく重要です。速いだけでは意味がありません。<br>正確さを重要視したタイピングの指標がこのスコアです。<br>この値が高いほど、正確で速い技能を持っているといえます。",
        },
        {
          title: "画面デザインや例文はカスタマイズできますか？",
          text: "ご希望があればお問い合わせからご連絡ください。",
          url: "<a href='/contact'>お問い合わせ</a>",
        },
      ],
      apiUrl: process.env.VUE_APP_API_BASE_URL,
    };
  },
  async created() {
    // 情報取得
    const url = this.$route.path.split("/");
    this.url = url[2];
    // 画面幅取得
    this.width = window.innerWidth;
    // サイズ調整
    if (this.url !== "check") {
      this.cardHeight = 200;
      this.imgWidth = 200;
    } else {
      this.cardHeight = 300;
      this.imgWidth = 300;
    }
    // ヘッド
    this.setup();
  },
  methods: {
    // ヘッダー情報
    setup() {
      const siteData = reactive({
        title: "よくある質問｜コリアンタイピング",
        description: "コリアンタイピングの「よくある質問」をまとめています。",
      });
      useHead({
        // Can be static or computed
        title: computed(() => siteData.title),
        meta: [
          {
            name: "description",
            content: computed(() => siteData.description),
          },
        ],
      });
    },
  },
});
</script>

<style scoped lang="scss">
.grey {
  color: #4d4d4d;
}
.qa-title {
  margin-top: 35px;
  margin-bottom: 10px;
  color: #4d4d4d;
}

.qa-description {
  padding: 20px;
  font-size: small;
}

.qa-link {
  padding-left: 20px;
  padding-bottom: 20px;
  font-size: small;
  margin-top: -10px;
}
</style>

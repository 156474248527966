<template>
  <v-app class="bg-img">
    <div class="py-3 bg-img"></div>
    <v-container>
      <!-- 上部のボタン -->
      <br />
      <v-row justify="center">
        <v-col md="8" cols="12">
          <v-card no-gutters>
            <v-card-title class="card-title">ブログ</v-card-title>
            <div v-if="this.loding == true">
              <loding />
            </div>
            <div v-else class="padding">
              <v-card-actions v-for="(blog, i) in blog" :key="i">
                <router-link :to="'/blog/' + blog.fields.url" class="deco-none"
                  >◆ {{ blog.fields.title }}</router-link
                >
              </v-card-actions>
            </div>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </v-app>
</template>

<script>
import loding from "../myLoding.vue";
import { createClient } from "@/plugins/contentful";

export default {
  name: "blogAll",
  components: {
    loding,
  },
  data: () => ({
    width: 0,
    blog: [],
    loding: true,
  }),
  async created() {
    // 画面幅取得
    this.width = window.innerWidth;
    await createClient()
      .getEntries({
        content_type: "blog",
        order: "-sys.createdAt",
      })
      .then((res) => {
        this.blog = res.items;
        this.loding = false;
      });
  },
};
</script>

<style>
@import url("https://fonts.googleapis.com/css2? family= M+PLUS+Rounded+1c & display=swap");

.v-application {
  font-family: "M PLUS Rounded 1c", sans-serif !important;
}
.card-title {
  font-family: "M PLUS Rounded 1c", sans-serif !important;
  background-color: #54b3fc;
  margin-bottom: 20px;
  color: white;
  font-weight: bold;
}
.deco-none {
  text-decoration: none;
  color: #4d4d4d;
  margin-bottom: 20px;
}
.grey-color {
  font-family: "M PLUS Rounded 1c", sans-serif !important;
  color: #4d4d4d;
}
.font-bold {
  font-family: "M PLUS Rounded 1c", sans-serif !important;
  font-weight: bold;
}

.bg-img {
  background-image: url("~@/assets/bg.png");
}

.bg-white {
  background-color: white;
}
.start-btn {
  margin: 0 auto;
}

.info {
  margin-top: 10px;
}
.select-btn {
  margin-top: 10px;
}

.padding {
  padding-left: 20px;
  padding-bottom: 10px;
}
</style>

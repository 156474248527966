<template>
  <v-app class="bg-img">
    <div class="py-3"></div>
    <v-container>
      <div class="py-3"></div>
      <v-card width="350px" class="mx-auto mt-4" cols="12" sm="4">
        <v-card-title class="card-title">
          <h3 class="display-2">退会</h3>
        </v-card-title>
        <v-card-text>
          <v-form>
            <p class="delete-font">本当に退会しますか？</p>
            <br />
            <p>
              ・退会するとランキングに参加できなくなります<br />
              ・同じメールアドレスでは会員登録できなくなりますのでご注意ください
              <br />
              <br />
              上記、問題なければ以下ボタンを押下してください。
            </p>
            <v-card-actions class="text-center">
              <v-btn color="red" @click="deleteUserID">退会する</v-btn>
            </v-card-actions>
          </v-form>
        </v-card-text>
      </v-card>
    </v-container>
  </v-app>
</template>
<script>
export default {
  data: () => ({
    url: "",
    userID: null,
    token: "",
    apiUrl: process.env.VUE_APP_API_BASE_URL,
  }),
  created: function () {
    this.getUserID();
  },
  methods: {
    getUserID() {
      if (localStorage.getItem("loginData") === null) {
        alert("ログインしてください");
        this.$router.push({ path: "/login" });
      } else {
        const obj = localStorage.getItem("loginData");
        const loginData = JSON.parse(obj);
        this.userID = loginData.userID;
        this.token = loginData.token;
        // this.token = loginData.token;
      }
    },
    deleteUserID() {
      console.log(this.userID);
      console.log(this.token);
      this.axios
        .post(
          this.apiUrl + "delete",
          {
            useID: Number(this.userID),
          },
          {
            headers: {
              Authorization: "Bearer " + this.token,
              "Content-type": "application/json",
              Accept: "application/json,",
            },
          }
        )
        .then((res) => {
          console.log(res.data);
          alert("退会が完了しました。");
          localStorage.clear();
          location.href = "/";
        })
        .catch((e) => {
          console.log(e);
          if (e.response.data.message === "Unauthenticated.") {
            alert("問題が発生しました。再度ログインをお願いします");
            localStorage.clear();
            this.$router.push({ path: "/login" });
          }
        });
    },
  },
};
</script>
<style lang="scss" scoped>
.delete-font {
  font-size: large;
  font-weight: bold;
  color: red;
}
</style>

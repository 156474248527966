<template>
  <v-app class="bg-img">
    <div class="py-3"></div>
    <v-container>
      <div class="py-3"></div>
      <v-card width="350px" class="mx-auto mt-4" cols="12" sm="4">
        <v-card-title class="card-title">
          <h3 class="display-2">ログイン</h3>
        </v-card-title>
        <v-card-text>
          <!-- <p v-if="this.prevRoute.path == '/letter'">
              ※購入にはログインが必要です
            </p> -->
          <v-form>
            <v-text-field
              prepend-icon="mdi-account-circle"
              label="メールアドレス"
              v-model="email"
            />
            <v-text-field
              v-bind:type="showPassword ? 'text' : 'password'"
              prepend-icon="mdi-lock"
              v-bind:append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
              label="パスワード"
              @click:append="showPassword = !showPassword"
              v-model="password"
            />
            <v-card-actions>
              <v-btn color="blue" @click="login">ログイン</v-btn>
            </v-card-actions>
            <p>
              <router-link to="/password-remind"
                >パスワードを忘れた方はこちら</router-link
              >
            </p>
            <br />
            <p>
              <router-link to="/sign-in">新規ユーザ登録はこちら</router-link>
            </p>
          </v-form>
        </v-card-text>
      </v-card>
    </v-container>
  </v-app>
</template>
<script>
// import axios from "axios";

export default {
  data: () => ({
    url: "",
    showPassword: false,
    email: "",
    password: "",
    id: "",
    apiUrl: process.env.VUE_APP_API_BASE_URL,
  }),
  created: function () {
    // メアド認証済、かつ、ログインしていたらホームに遷移
    if (
      localStorage.getItem("loginData") !== null &&
      localStorage.getItem("emailVerified") === "OK"
    ) {
      this.$router.push({ path: "/" });
    }

    // パラメータ取得
    const key = "bdanmoelikcjhfg";
    let pass = "";
    // console.log(this.$route.query.id);
    if (this.$route.query.id !== undefined) {
      pass = this.$route.query.id;
      for (let i = 0; i < pass.length; i++) {
        this.id += key.indexOf(pass[i]);
      }
    } else {
      this.id = null;
    }
    // console.log(this.id);
  },
  methods: {
    login: async function () {
      if (this.email === "" || this.password === "") {
        alert("入力項目はすべて必須項目です");
        return;
      }
      // axios.withCredentials = true;
      await this.axios
        .post(this.apiUrl + "login", {
          email: this.email,
          password: this.password,
          id: this.id,
        })
        .then((res) => {
          // console.log(res.data);
          // 初回ログイン or 正常ログイン
          let obj = "";
          if (res.data.status_code === 200 || res.data.status_code === 201) {
            alert("ログインに成功しました!");
            console.log(res.data);
            const token = res.data.token.split("|");
            const loginData = {
              userID: res.data.id,
              token: token[1],
              emailVerified: res.data.email_verified,
            };
            obj = JSON.stringify(loginData);
            localStorage.setItem("loginData", obj);
            location.href = "/";

            // メール認証不可(未実施)
          } else if (res.data.status_code === 202) {
            const token = res.data.token.split("|");
            const loginData = {
              userID: res.data.id,
              token: token[1],
              emailVerified: res.data.email_verified,
            };
            obj = JSON.stringify(loginData);
            localStorage.setItem("loginData", obj);
            alert("メール認証が終わっていません。メール認証をお願いします。");
            this.$router.replace("/mail-send");
            // 退会済
          } else if (res.data.status_code === 205) {
            alert("退会済のようです。再度新規会員登録をお願いします");
            this.$router.replace("/sign-in");
            // ユーザがいない
          } else if (res.data.status_code === 500) {
            alert("ユーザが見つかりません。再度ログインをお試しください");
            this.email = "";
            this.password = "";

            // それ以外
          } else {
            alert("認証に失敗しました。再度ログインをお試しください");
            this.email = "";
            this.password = "";
          }
        });
      // それ以外
      // .catch((error) => {
      //   if (error.response.request.status === 422) {
      //     alert("認証に失敗しました。");
      //     return;
      //   }
      //   if (error.response.request.status === 500) {
      //     alert("認証に失敗しました。");
      //     this.email = "";
      //     this.password = "";
      //     return;
      //   }
      // });
    },
  },
};
</script>
<style lang="scss" scoped></style>

<template>
  <v-app>
    <v-container> 認証処理中 </v-container>
  </v-app>
</template>

<script>
export default {
  data: () => ({
    id: "",
    hash: "",
    expires: "",
    query: "",
    apiUrl: process.env.VUE_APP_API_BASE_URL,
  }),
  async created() {
    console.log("id: " + this.$route.query.id);
    this.id = this.$route.query.id;
    let pass = "";
    for (let i = 0; i < this.id.length; i++) {
      pass += this.convertToInt(this.id[i]);
    }
    console.log(pass);
    await this.axios
      .get(
        this.apiUrl +
          "email/verify/" +
          this.$route.query.id +
          "/" +
          this.$route.query.hash +
          "?expires=" +
          this.$route.query.expires +
          "&signature=" +
          this.$route.query.signature,
        {
          headers: {
            Authorization: "Bearer " + this.$route.query.token.split("|")[1],
            "Content-type": "application / json",
            Accept: "application/json,",
            data: {},
          },
        }
      )
      .then((res) => {
        console.log(res.data);
        //   await this.$auth.fetchUser();
        this.$router.replace("/login");
      })
      .catch((e) => {
        console.log(e);
        this.$router.replace("/login?id=" + pass);
      });
  },
  methods: {
    convertToInt(a) {
      const key = "bdanmoelikcjhfg";
      return key.charAt(a);
    },
  },
};
</script>

const contentful = require("contentful");
// use default environment config for convenience
// these will be set via `env` property in nuxt.config.js
const config = {
  space: "ra1uck6ttjuz",
  accessToken: "FlsrWvpyevRk3VIHqXSa9MifnL6RU-y0IMkS--KQZXo",
};

// export `createClient` to use it in page components
module.exports = {
  createClient() {
    return contentful.createClient(config);
  },
};

let checkTypingLevel = (score) => {
  let level = "";
  if (score < 21) {
    level = "E-";
  } else if (score < 38) {
    level = "E";
  } else if (score < 55) {
    level = "E+";
  } else if (score < 72) {
    level = "D-";
  } else if (score < 89) {
    level = "D";
  } else if (score < 106) {
    level = "D+";
  } else if (score < 123) {
    level = "C-";
  } else if (score < 140) {
    level = "C";
  } else if (score < 157) {
    level = "C+";
  } else if (score < 174) {
    level = "B-";
  } else if (score < 191) {
    level = "B";
  } else if (score < 208) {
    level = "B+";
  } else if (score < 225) {
    level = "A-";
  } else if (score < 242) {
    level = "A";
  } else if (score < 259) {
    level = "A+";
  } else if (score < 276) {
    level = "S";
  } else if (score < 299) {
    level = "Good!!!";
  } else if (300 <= score) {
    level = "コリアンタイピングマスター！！！";
  }
  return level;
};

export default checkTypingLevel;

<template>
  <v-app class="bg-img">
    <div class="py-3"></div>
    <v-container>
      <div v-if="this.loding == true">
        <loding />
      </div>
      <div v-else>
        <v-row justify="center">
          <v-col cols="12" sm="8">
            <v-sheet rounded="lg" class="blog">
              <!--  -->
              <p v-if="updatedAt === ''">登録日：{{ this.createdAt }}</p>
              <p v-else>更新日：{{ this.updateAt }}</p>
              <div class="py-2"></div>
              <h2 class="grey">{{ info.fields.title }}</h2>
              <div class="py-2"></div>
              <div class="grey" v-html="info.fields.text"></div>
            </v-sheet>
          </v-col>
        </v-row>
      </div>
      <div class="py-3"></div>
    </v-container>
  </v-app>
</template>

<script>
import { defineComponent, computed, reactive } from "vue";
import { useHead } from "@vueuse/head";
import { createClient } from "@/plugins/contentful";
import loding from "../myLoding.vue";

export default defineComponent({
  components: {
    loding,
  },
  data() {
    return {
      info: [],
      infos: [],
      url: "",
      updateAt: "",
      createdAt: "",
      loding: true,
    };
  },
  methods: {
    // ヘッダー情報
    setup() {
      const siteData = reactive({
        title: this.info.fields.seoTitle,
        description: this.info.fields.seoDiscription,
      });
      useHead({
        // Can be static or computed
        title: computed(() => siteData.title),
        meta: [
          {
            name: "description",
            content: computed(() => siteData.description),
          },
        ],
      });
    },
    // 日付の変更
    dateChange(date) {
      var dt = date;
      var y = dt.getFullYear();
      var m = ("00" + (dt.getMonth() + 1)).slice(-2);
      var d = ("00" + dt.getDate()).slice(-2);
      var result = y + "/" + m + "/" + d;
      return result;
    },
  },
  // テンプレートの取得取得
  async created() {
    // 情報取得
    const url = this.$route.path.split("/");
    this.url = url[2];
    await createClient()
      .getEntries({
        content_type: "info",
        "fields.url": this.url,
      })
      .then((res) => {
        console.log(res);
        this.loding = false;
        this.info = res.items[0];
        this.setup();
        const created = new Date(res.items[0].sys.createdAt);
        const updated = new Date(res.items[0].sys.updatedAt);
        this.createdAt = this.dateChange(created);
        this.updateAt = this.dateChange(updated);
      });
  },
});
</script>

<style scoped>
.grey {
  color: #4a4949;
}

.div {
  width: 100%;
}
.blog {
  padding: 30px;
}

.blog-title {
  margin-left: 40px;
  margin-bottom: 20px;
  color: #4a4949;
}

.deco-none-blue {
  text-decoration: none;
  color: rgb(52, 52, 228);
  margin-bottom: 20px;
}
</style>

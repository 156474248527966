<template>
  <v-app>
    <v-container justify="center" class="text-center">
      <ma-5></ma-5>
      <stripe-buy-button
        buy-button-id="buy_btn_1N4KITABT1ncg77m0vLEvrix"
        publishable-key="pk_test_51MrJUsABT1ncg77mDzcy9tHr9ARf2rWo6eUh8SaO9ZEIaQBjotalvPYAo2JX6zr5w6I64F5r5pBCXU42UKeLOlGX00D3lcgjjz"
      >
      </stripe-buy-button>
    </v-container>
  </v-app>
  <!-- <div>
    <div id="buy-button"></div>
  </div> -->
</template>

<script>
export default {
  name: "BuyButton",
  //   mounted() {
  //     const stripe = Stripe("your-publishable-key");
  //     const buyButton = stripe.buyButton({
  //       // Buy Buttonの設定
  //       // ...
  //     });
  //     buyButton.mount("#buy-button");
  //   },
};
</script>

<template>
  <div class="text-center">
    <v-progress-circular indeterminate color="blue"></v-progress-circular>
    <p class="bold" color="blue">ローディング中...</p>
  </div>
</template>

<script></script>

<style scoped>
.v-progress-circular {
  margin: 1rem;
}
.bold {
  font-weight: bold;
  color: #81acf2;
  font-size: 15px;
}
</style>
